import { Service } from "./Service";
import config from "../config";
const proxy: string | undefined = config.proxy_url;

export enum SignatureStatus
{
    Pending,
    Signed,
    Sent,
    Error
}
export interface ISignature
{
    id?: number,
    createdAt?: string | Date,
    updatedAt?: string | Date,
    expiresAt?: string | Date,
    signAt?: string | Date,
    signatureCode?: string,
    phone?: string,
    status: SignatureStatus,
    hasDisclaimerTime?: number
}
export interface IProcessHours
{
    id: number,
    risk: number,
    hour: string
}
export interface ErrorResponse
{
    status: boolean,
    message: string,
}
export interface ISignBody
{
    signatureCode: string
}
class SignatureService extends Service<ISignature>
{
    public getSignature(id: number, params?: any)
    {
        return Service.request<ISignature>(
        {
            method: "get",
            url: `/signatures/${id}`,
            params
        }, proxy);
    }
    public putSignature(id: number, signBody: ISignBody)
    {
        return Service.request<ISignature>(
        {
            method: "put",
            url: `/signatures/${id}`,
            data: signBody
        }, proxy);
    }
    public putSignatures(signBody: any)
    {
        return Service.request<ISignature[]>(
        {
            method: "put",
            url: `/signatures`,
            data: signBody
        }, proxy);
    }
    public getRefreshSignature(params?: any)
    {
        return Service.request<ISignature[]>(
        {
            method: "get",
            url: `/refreshSignatures`,
            params
        }, proxy);
    }
    public createSignatureNoPlan(userId: number, params?: any)
    {
        return Service.request<ISignature[]>(
        {
            method: "get",
            url: `/CreateSignatureNoPlan/${userId}`,
            params
        }, proxy); 
    }
    public createSignatureByFinametrixContract(savingPlanId: number, params?: any)
    {
        return Service.request<ISignature[]>(
        {
            method: "get",
            url: `/CreateSignatureByFinametrixContract/${savingPlanId}`,
            params
        }, proxy);
    }

    public createSignaturesBySavingsPlan(savingPlanId: number, promotionSlug: string, contractDocName: string)
    {
        return Service.request<ISignature[]>(
        {
            method: "get",
            url: `/createSignaturesBySavingsPlan/${savingPlanId}/${promotionSlug}/${contractDocName}`
        }, proxy);
    }


    public requestSignatureNoPlanByDni(idDocument: string)
    {
        return Service.request<ISignature>(
        {
            method: "get",
            url: `/requestSignatureNoPlanByDni/${idDocument}`
        }, proxy); 
    }
    public getProcessHours()
    {
        return Service.request<IProcessHours[]>(
        {
            method: "get",
            url: `/GetProcessHours`,
        }, proxy)
    }
}

const service = new SignatureService();
export default service;