import { useEffect, useState } from 'react';
import Attachments from '../services/AttachmentService';

interface AttachmentProps {
    fileName: string,
    className: string
};

export const Attachment = (props: AttachmentProps) => {
    const [base64Image, setBase64Image] = useState<string>("");
    const [base64UrlImage, setBase64UrlImage] = useState<string>("");

    useEffect(() => {
        Attachments.getBase64Attachment(props.fileName)
            .then((response: any) => {
                setBase64Image(response);
            });
    }, [props.fileName]);

    useEffect(() => {
        Attachments.getBlobAttachment(props.fileName)
            .then((response: any) => {
                const file = new Blob(
                    [response.data],
                    { type: response.headers['content-type'] });
                setBase64UrlImage(URL.createObjectURL(file));
            });
    }, [props.fileName]);

    return (
        <a href={base64UrlImage} target="_blank" rel="noreferrer" download={props.fileName}>
            <img src={base64Image} className={props.className} alt={' Cargando...'} />
        </a>
    );
};
