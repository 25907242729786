import { DefaultButton, DirectionalHint, Dropdown, IDropdownOption, IIconProps, IconButton, Modal, Spinner, SpinnerSize, TeachingBubble, Toggle } from "@fluentui/react";
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import ElementsService, { ElementAmountType, ServiceType, UpdateElementsAmountType } from "../../../services/ElementsService";
import React, { useState } from "react";
import { ai } from "../../../services/TelemetryService";
import PortfolioModelWalletService, { ModelWallet } from "../../../services/PortfolioModelWalletService";
import Auth from "../../../Auth/Auth";
import { useHistory } from "react-router";
import { dropdownStyles } from "../../dropdown/dropdownStyles";
import SavingsPlanService from "../../../services/SavingsPlanService";

const cancelIcon: IIconProps = { iconName: "Cancel" };

type Props = {activePlan: any};

const PortfolioBalancer = (props: Props) =>
{
    const theme = window.localStorage.getItem("theme");
    const user = Auth.getUserProfile();
    const appInsights = ai.reactPlugin;
    const history = useHistory();
    const { activePlan } = props;
    const [balancerError, setBalancerError] = useState<string>("");
    const [savingPlanModelPortfolios, setSavingPlanModelPortfolios] = useState<IDropdownOption[]>([]);
    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<IDropdownOption | null>();
    const [showBalanceModelPortfolioModal, setShowBalanceModelPortfolioModal] = useState(false);
    const [balanceModelPortfolioModalChecked, setBalanceModelPortfolioModalChecked] = useState(false);
    const [balancerPortfolioModelPrice, setBalancerPortfolioModelPrice] = useState<ServiceType>();
    const [balancerModelPortfolioToggle, setBalancerModelPortfolioToggle] = useState<boolean>(false);
    const [tooltipBalancer, setTooltipBalancer] = useState(false);
    const [elementsAmount, setElementsAmount] = useState<ElementAmountType>();
    const [loadingBalancerPrice, setLoadingBalancerPrice] = useState<boolean>(true);
    const [loadingModelWallets, setLoadingModelWallets] = useState<boolean>(true);
    const [loadingElementsAmount, setloadingElementsAmount] = useState<boolean>(false);
    const [loadingBalancer, setLoadingBalancer] = useState<boolean>(false);

    React.useEffect(() =>
    {
        let balancePortfolioServiceTypeid = 2;
        setLoadingBalancerPrice(true);
        ElementsService.servicePrice(balancePortfolioServiceTypeid).then((response: ServiceType) =>
        {
            ElementsService.getLastElementsPrice().then(priceResponse =>
            {
                const elementAmountTypeResponse =
                {
                    ...response,
                    elementWater: priceResponse === 0 ? 0 : Number((((response?.elementIA ?? 0) / 100) * (1 / priceResponse)).toFixed(2))
                };
                setBalancerPortfolioModelPrice(elementAmountTypeResponse);
            });
        })
        .finally(() =>
        {
            setLoadingBalancerPrice(false);
        });
    }, []);

    React.useEffect(() =>
    {
        if(activePlan.id)
        {
            setLoadingModelWallets(true);
            PortfolioModelWalletService.getModelWallets(activePlan.id).then((response: ModelWallet[]) =>
            {
                let spModelPortfolios: IDropdownOption[] = response.map((portfolio: ModelWallet) => (
                {
                    key: portfolio.id.toString(),
                    text: portfolio.name,
                    id: portfolio.id.toString()
                }));
                setSavingPlanModelPortfolios(spModelPortfolios);
            })
            .catch((error: any) =>
            {
                setBalancerError(error);
            })
            .finally(() =>
            {
                setLoadingModelWallets(false);
            });
        }
    }, [activePlan.id]);

    React.useEffect(() =>
    {
        if(user.id)
        {
            setloadingElementsAmount(true);
            ElementsService.getElementsAmountBySavingsPlanId(activePlan.id).then((elementsAmountAux: ElementAmountType) =>
            {
                setElementsAmount(elementsAmountAux);
            })
            .catch(() => { })
            .finally(() =>
            {
                setloadingElementsAmount(false);
            });
        }
    }, [user.id]);

    const onChangeModelWalletDropDown = (item?: IDropdownOption) =>
    {
        if(item?.id !== undefined)
        {
            setSelectedModelPortfolio(savingPlanModelPortfolios.find(x => x.id === item?.id));
        }
    };

    const buyBalancer = async () =>
    {
        setLoadingBalancer(true);
        setBalancerError("");

        if(balancerPortfolioModelPrice)
        {
            appInsights.trackEvent(
            {
                name: "Rebalanceo de cartera",
                properties: { userId: user?.id, planId: activePlan.id, portfolioModelId: selectedModelPortfolio?.id }
            });

            let enoughElements: boolean = false;

            let elements: UpdateElementsAmountType =
            {
                savingsPlanId: activePlan.id,
                concept: "Adquirido rebalanceo de cartera",
                description: "Cartera Modelo: " + selectedModelPortfolio?.text + " - Plan: " + activePlan.name
            };

            if(balancerModelPortfolioToggle)
            {
                let elementsToPay = balancerPortfolioModelPrice?.elementWater!;
                elements.water = -1 * elementsToPay;
                enoughElements = (elementsAmount?.elementWater ?? 0) >= elementsToPay;
            }
            else
            {
                let elementsToPay = balancerPortfolioModelPrice?.elementIA!.toFixed(2);
                elements.iA = -1 * Number(elementsToPay);
                enoughElements = (elementsAmount?.elementIA ?? 0) >= Number(elementsToPay);
            }

            let correctTransaction: boolean | void = false;
            if(enoughElements)
            {
                correctTransaction = await ElementsService.updateElementsAmount(elements).catch(() =>
                {
                    setLoadingBalancer(false);
                    setBalancerError("Lo sentimos ha ocurrido un error inesperado al pagar con elements, intentaremos resolverlo lo antes posible, disculpe las molestias.");
                    return;
                });
            }
            else
            {
                setLoadingBalancer(false);
                setBalancerError("No tienes suficientes elements.");
                return;
            }

            if(correctTransaction)
            {
                rebalancearCartera();
            }
            else
            {
                setLoadingBalancer(false);
                setBalancerError("Lo sentimos ha ocurrido un error inesperado al pagar con elements, intentaremos resolverlo lo antes posible, disculpe las molestias.");
                return;
            }
        }
        else
        {
            setBalancerError("Lo sentimos algo ha salido mal.");
            setLoadingBalancer(false);
            return;
        }
    };

    const rebalancearCartera = () =>
    {
        if(selectedModelPortfolio?.id !== undefined && selectedModelPortfolio.id !== null)
        {
            setLoadingBalancer(true);
            SavingsPlanService.rebalance(activePlan.id, parseInt(selectedModelPortfolio.id)).then(orders =>
            {
                history.push("/portfolio/balancer", { orders: orders });
            })
            .catch(() =>
            {
                setBalancerError("Lo sentimos ha ocurrido un error inesperado al generar las órdenes, intentaremos resolverlo lo antes posible, disculpe las molestias.");
            })
            .finally(() =>
            {
                setLoadingBalancer(false);
            });
        }
    };

    return(
        <React.Fragment>
            <div className="ms-Grid-row" style={{ marginBottom: "15px" }}>
                {(loadingBalancerPrice || loadingBalancer || loadingElementsAmount || loadingModelWallets) && <Spinner size={SpinnerSize.medium} />}
                {!loadingBalancerPrice && !loadingBalancer && !loadingElementsAmount && !loadingModelWallets &&
                (
                    <React.Fragment>
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg5 ms-xl5 ms-xxl5 ms-xxxl4 text-right" style={{paddingRight: "10px", margin: "10px 0px"}}>
                            Rebalancear contra cartera modelo
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5 ms-xl5 ms-xxl5 ms-xxxl6" style={{paddingRight: "20px", margin: "10px 0px"}}>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11 ms-xl11 ms-xxl11 ms-xxxl11">
                                    <Dropdown placeholder={"Selecciona una Cartera Modelo"}
                                        style={{ width: "100%", marginTop: "-15px" }}
                                        disabled={showBalanceModelPortfolioModal}
                                        selectedKey={selectedModelPortfolio?.key ?? undefined}
                                        options={savingPlanModelPortfolios as IDropdownOption[]}
                                        onChange={(event, item) =>
                                        {
                                            onChangeModelWalletDropDown(item);
                                        }}
                                        styles={
                                        {
                                            ...dropdownStyles,
                                            root: { width: "100%" },
                                            title:
                                            {
                                                color: "#cc214F !important",
                                                borderColor: "transparent !important",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                marginTop: "3px"
                                            },
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1 ms-xxl1 ms-xxxl1">
                                    <InfoIcon
                                        id="tooltipBalancerToModelWallet"
                                        onMouseEnter={() => setTooltipBalancer(true)}
                                        onMouseLeave={() => setTooltipBalancer(false)}
                                        style={{ fontSize: "20px", marginTop: "0px" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2">
                            <DefaultButton className="button mini-primary-button"
                                disabled={!selectedModelPortfolio || !balancerPortfolioModelPrice}
                                onClick={() =>
                                {
                                    setShowBalanceModelPortfolioModal(true);
                                }}
                            >
                                Rebalancear
                            </DefaultButton>
                        </div>
                    </React.Fragment>
                )}
                {tooltipBalancer === true &&
                (
                    <TeachingBubble
                        target="#tooltipBalancerToModelWallet"
                        hasCloseButton={false}
                        headline=""
                        onDismiss={() => setTooltipBalancer(false)}
                        calloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                    >
                        <div>
                            El servicio de rebalanceo de carteras permite crear los traspasos necesarios en tu cartera actual para mantener los porcentajes de los
                            fondos de la cartera modelo que seleccione.
                        </div>
                        <br />
                        <div>Este servicio tiene un coste en Elements IA o Elements Agua que serán retirados al pulsar el botón rebalancear.</div>
                    </TeachingBubble>
                )}
                {showBalanceModelPortfolioModal &&
                (
                    <React.Fragment>
                        <Modal styles={
                            {
                                main:
                                {
                                    color: theme === "dark" ? "white" : "black",
                                    width: "700px !important",
                                    minWidth: "400px !important",
                                    height: "auto !important",
                                    minHeight: "auto !important",
                                }
                            }}
                            isOpen={showBalanceModelPortfolioModal}
                            onDismiss={() =>
                            {
                                setShowBalanceModelPortfolioModal(false);
                            }}
                            isBlocking={false}
                        >
                            <div style={{ margin: "15px", display: "inline-block" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ alignSelf: "center", fontSize: "15px" }}><b>¿Con qué tipo de Elements desea pagar?</b></div>
                                    <div style={{ justifyContent: "end", display: "flex" }}>
                                        <IconButton iconProps={cancelIcon}
                                            ariaLabel="Cerrar"
                                            className="close-button"
                                            onClick={() =>
                                            {
                                                setShowBalanceModelPortfolioModal(false);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-col" style={{ margin: "20px 0px" }}>
                                    <Toggle
                                        label={"Elements IA"}
                                        inlineLabel
                                        onText="Elements Agua"
                                        offText="Elements Agua"
                                        checked={balancerModelPortfolioToggle}
                                        onChange={(ev, checked) =>
                                        {
                                            setBalancerModelPortfolioToggle(checked ?? false);
                                        }}
                                        styles={
                                        {
                                            root: { margin: 0 },
                                            label: { margin: 0, paddingBottom: 0, paddingTop: "0px", paddingRight: "10px" },
                                            container: { paddingTop: "0px", alignItems: "center" }
                                        }}
                                    />
                                </div>
                                <p>
                                    Acabas de seleccionar rebalancear tu cartera actual a la cartera modelo <b>{selectedModelPortfolio?.text}</b>, si pulsas el
                                    botón aceptar se retirarán{" "}
                                    <b>
                                        {balancerModelPortfolioToggle
                                            ? balancerPortfolioModelPrice?.elementWater + " Elements Agua "
                                            : balancerPortfolioModelPrice?.elementIA + " Elements IA "}
                                    </b>
                                    de tu cuenta.
                                </p>
                                <p>En la pantalla siguiente aparecerán los traspasos que se van a realizar para su firma.</p>
                                <div style={
                                    {
                                        backgroundColor: "#FFF1BE",
                                        boxShadow: "rgb(0 0 0 / 25%) 0.5px 1px 2px",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }}
                                >
                                    <input type="checkbox"
                                        style={{ marginRight: "10px" }}
                                        onChange={e =>
                                        {
                                            setBalanceModelPortfolioModalChecked(e.target.checked);
                                        }}
                                    />
                                    Acepto que se retiren{" "}
                                    <b>
                                        {balancerModelPortfolioToggle
                                            ? balancerPortfolioModelPrice?.elementWater + " Elements Agua "
                                            : balancerPortfolioModelPrice?.elementIA + " Elements IA "}
                                    </b>
                                    de mi cuenta que no se devolverán en caso de no firmar los traspasos por cualquier motivo, salvo incidencia técnica del
                                    proceso notificada por correo eléctrónico a info@ironia.tech.
                                </div>
                                <div style={{marginTop: "20px", float: "right"}}>
                                    <DefaultButton className="button mini-primary-button"
                                        style={{ marginRight: "10px" }}
                                        onClick={() =>
                                        {
                                            setBalanceModelPortfolioModalChecked(false);
                                            setShowBalanceModelPortfolioModal(false);
                                        }}
                                    >
                                        Cancelar
                                    </DefaultButton>
                                    <DefaultButton className="button mini-primary-button"
                                        disabled=
                                        {
                                            !selectedModelPortfolio || !balanceModelPortfolioModalChecked || !balancerPortfolioModelPrice || !elementsAmount
                                        }
                                        onClick={() =>
                                        {
                                            setLoadingBalancer(true);
                                            setBalanceModelPortfolioModalChecked(false);
                                            setShowBalanceModelPortfolioModal(false);
                                            buyBalancer();
                                        }}
                                    >
                                        {loadingBalancer && <Spinner size={SpinnerSize.small} style={{ marginRight: "5px" }} />}
                                        Aceptar
                                    </DefaultButton>
                                </div>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}
            </div>
            {balancerError.length > 0 && <p style={{color: "#CC214F"}}>{balancerError}</p>}
        </React.Fragment>
    );
};

export default PortfolioBalancer;