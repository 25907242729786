import { Spinner, SpinnerSize } from "@fluentui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import Auth from "../../Auth/Auth";
import SavingsPlanService from "../../services/SavingsPlanService";
import * as Style from "./ActiveSavingPlansStyle";
import TableActiveSavingPlans, { ITableSPlansActive } from "./TableActiveSavingPlans";
import UserService from "../../services/UserService";
import { useHistory } from "react-router-dom";
import React from "react";
import { useDispatch } from "react-redux";
import { setActiveSavingsPlan, setSavingsPlanElementsStatus } from "../../store/auth/actions";

const ExpiredSavingPlans = ({data, loading, error}: {data?: ITableSPlansActive[], loading: boolean, error: any}) =>
{
    var columns = ["Planes de Cartera y Carteras Modelo Asociadas", "Fecha de Inicio", "Fecha de Validez", "Tipo de Plan", "Renovar"];
    const theme = useContext(ThemeContext);
    const userId = Auth.getUserProfile().id;
    const [savingsPlans, setSavingsPlans] = useState<ITableSPlansActive[]>();
    const [saveActivePlan, setActivePlan] = useState(-1);
    const [updatePlanName, setUpdatePlanName] = useState("");
    const [updatePlanId, setUpdatePlanId] = useState(-1);
    const firstTime = useRef<boolean>(false);
    const hasToRedirect = useRef<boolean>(false);
    const changeName = useRef<boolean>(false);
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() =>
    {
        if(data !== undefined && savingsPlans === undefined)
        {
            setSavingsPlans(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const notifyChangedActivePlan = async (activePlan: any) =>
    {
        await dispatch(setActiveSavingsPlan({ ...activePlan, sync: true }));
        await dispatch(setSavingsPlanElementsStatus(activePlan.planId));
    };

    const savePlan = (e: any, hTR: boolean) =>
    {
        if(hTR === true)
        {
            firstTime.current = true;
            hasToRedirect.current = true;
            setActivePlan(e);
        }
        else
        {
            firstTime.current = true;
            hasToRedirect.current = false;
            setActivePlan(e);
        }
    };

    const updatePlan = (n: any, i: number) =>
    {
        changeName.current = true;
        setUpdatePlanName(n);
        setUpdatePlanId(i);
    };

    useEffect(() =>
    {
        if(changeName.current === true)
        {
            changeName.current = false;
            SavingsPlanService.updateSavingPlanName(updatePlanId, userId, updatePlanName).then(async (response: any) =>
            {
                if(response === true)
                {
                    var aux = Auth.getActivePlan();

                    if(aux.id === updatePlanId && updatePlanName !== aux.name)
                    {
                        var item: any =
                        {
                            planId: updatePlanId,
                            planName: updatePlanName,
                            userId: userId,
                            type: aux.type,
                            status: 4,
                            outdated: aux.outdated,
                            outdatedContract: aux.outdatedContract,
                            cancelled: aux.cancelled,
                            portfolio: aux.portfolio,
                            finametrixContract: aux.finametrixContract,
                            userSubscriptionId: aux.userSubscriptionId,
                        };

                        Auth.setActivePlan(item);
                    }

                    var auxData: ITableSPlansActive[] = [];

                    if(savingsPlans !== undefined)
                    {
                        for(var i=0; i<savingsPlans?.length; i++)
                        {
                            if(savingsPlans[i].id === updatePlanId)
                            {
                                savingsPlans[i].planName = updatePlanName;
                                auxData.push(savingsPlans[i]);
                            }
                            else
                            {
                                auxData.push(savingsPlans[i]);
                            }
                        }

                        setSavingsPlans(auxData);
                    }
                }
            });
        }
    }, [updatePlanId, updatePlanName, userId, savingsPlans]);

    useEffect(() =>
    {
        if(firstTime.current === true)
        {
            firstTime.current = false;
            UserService.selectActivePlan(userId, saveActivePlan).then((response: any) =>
            {
                if(response === true)
                {
                    var auxData: ITableSPlansActive[] = [];

                    if(savingsPlans !== undefined)
                    {
                        for(var i=0; i<savingsPlans.length; i++)
                        {
                            auxData.push(savingsPlans[i]);
                            if(auxData[i].id === saveActivePlan)
                            {
                                auxData[i].selected = true;
                                var item: any =
                                {
                                    planId: saveActivePlan,
                                    planName: auxData[i].planName,
                                    userId: userId,
                                    status: 4,
                                    outdated: auxData[i].outdated,
                                    outdatedContract: auxData[i].outdatedContract,
                                    cancelled: auxData[i].cancelled,
                                    type: auxData[i].type,
                                    portfolio: auxData[i].portfolio,
                                    finametrixContract: auxData[i].finametrixContract,
                                    managedPortfolioFinametrixId: auxData[i].managedPortfolioFinametrixId,
                                    usersSubscriptionId: auxData[i].userSubscriptionId,
                                };

                                Auth.setActivePlan(item);
                                notifyChangedActivePlan(item);
                            }
                            else
                            {
                                auxData[i].selected = false;
                            }
                        }

                        if(hasToRedirect.current === true)
                        {
                            hasToRedirect.current = false;
                            setSavingsPlans(auxData);
                            history.push("/portfolio");
                        }
                        else
                        {
                            setSavingsPlans(auxData);
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveActivePlan, userId, savingsPlans, history]);

    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);

    if(error.message)
    {
        return(
            <div>
                Error:{" "}
                {
                    // @ts-ignore
                    error.message
                }
            </div>
        );
    }
    return(
        <React.Fragment>
            <React.Fragment>
                <Style.Wrapper>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md8">
                            <Style.TitleContainer>
                                <h3 style={{marginTop: "1.4em", cursor: "default"}}>
                                    Mis planes de cartera caducados
                                </h3>
                            </Style.TitleContainer>
                        </div>
                    </div>
                    <Style.MainCont>
                        <Style.TableCont>
                            <Style.SubTitle theme={theme}>
                                Planes de Cartera ({savingsPlans?.length}) y Carteras Modelo
                            </Style.SubTitle>
                            <Style.TableContainer theme={theme}>
                                {loading === true && <Spinner size={SpinnerSize.large} />}
                                {loading === false &&
                                (
                                    <TableActiveSavingPlans theme={theme} columnTitles={columns}
                                        rowData={savingsPlans as ITableSPlansActive[]} userId={userId}
                                        onClick={(ev: any, item: any, hTR: any) =>
                                        {
                                            savePlan(item, hTR);
                                        }}
                                        onSubmit={(item: any, id: number) =>
                                        {
                                            updatePlan(item, id);
                                        }}
                                    />
                                )}
                            </Style.TableContainer>
                        </Style.TableCont>
                    </Style.MainCont>
                </Style.Wrapper>
            </React.Fragment>
        </React.Fragment>
    );
};

export default ExpiredSavingPlans;