import React, { useContext, useState } from "react";
import DocumentTitle from "react-document-title";
import * as Element from "./MapConfigurationStyles";
import './MapConfigurationStyle.sass';
import { Separator, TeachingBubble, IButtonProps } from "@fluentui/react";
import savingsPlans, { MapConfigurationData, MapConfigurationRepaymentObject } from "../../services/SavingsPlanService";
import users from "../../services/UserService";
import Auth from "../../Auth/Auth";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { ChevronDownIcon, ChevronUpIcon, LightbulbIcon } from "@fluentui/react-icons-mdl2";
import { useBoolean } from "@fluentui/react-hooks";

interface RouteParams { };
interface Props extends RouteComponentProps<RouteParams> { };

export const MapConfiguration: React.FunctionComponent<Props> = () => {
    const userId = Auth.getUserProfile().id;
    const savingPlan = Auth.getActivePlan();
    const { state } = useLocation<any>();
    const history = useHistory();
    const savingPlanId = React.useRef<any>(state?.savingPlanId);
    const savingPlanName = React.useRef<any>(state?.savingPlanName);
    const [statusColor, setStatusColor] = React.useState<any>("#908B8B");
    const [stepMap, setStepMap] = React.useState<string>("1.1");
    const [configurationMapData, setConfigurationMapData] = useState<MapConfigurationData>();
    const [link, setLink] = React.useState<string>("");
    const [isCheckingPlan, setIsCheckingPlan] = React.useState<boolean>(false);
    const [timeChecking, setTimeChecking] = React.useState<string>("0 H 00 min");
    const [isMapEnabled, setIsMapEnabled] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<string>("NO Activo");
    const [showNext, setShowNext] = React.useState<boolean>(false);
    const [isSaverPlan, setIsSaverPlan] = React.useState<boolean>();
    const [isAlchemyPlan, setIsAlchemyPlan] = React.useState<boolean>();
    const [isSponsoredPlan, setIsSponsoredPlan] = React.useState<boolean>();
    const [isExperiencePlan, setIsExperiencePlan] = React.useState<boolean>();
    const theme = useContext(ThemeContext);
    const [outdatedPlan, setOutdatedPlan] = React.useState<boolean>(false);
    const [repaymentObject, setRepaymentObject] = React.useState<MapConfigurationRepaymentObject>({ userSubscriptionId: 0, subscriptionId: 0, limitAmount: 0.0, type: "" });
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isSubscriptionExpanded, setIsSubscriptionExpanded] = React.useState<boolean>(false);
    const [isTitularesExpanded, setIsTitularesExpanded] = React.useState<boolean>(false);
    const [isVerificationExpanded, setIsVerificationExpanded] = React.useState<boolean>(false);
    const [isPortfolioExpanded, setIsPortfolioExpanded] = React.useState<boolean>(false);
    const firstTimeLoading = React.useRef<boolean>(true);
    const [windowSize, setWindowSize] = React.useState<any>(window.innerWidth);
    const [tooltipClassToggle21, { setTrue: showTooltipClass21, setFalse: hideTooltipClass21 }] = useBoolean(false);
    const [tooltipStep21, setTooltipStep21] = useState<number>(0);

    const step21PrimaryButtonProps: IButtonProps = React.useMemo(() =>
    (
        {
            children: 'Siguiente',
            disabled: tooltipStep21 === 3,
            onClick: () => setTooltipStep21(prevState => prevState + 1),
            className: 'mini-primary-button'
        }),
        [tooltipStep21],
    );

    const step21SecondaryButtonProps: IButtonProps = React.useMemo(() =>
    (
        {
            children: 'Anterior',
            disabled: tooltipStep21 === 0,
            onClick: () => setTooltipStep21(prevState => prevState - 1),
            className: 'mini-primary-button'
        }),
        [tooltipStep21],
    );

    function chooseLink(step: string) {
        switch (step) {
            case "1.1":
            case "1.2":
            default:
                return "/savingsPlans/new";
            case "2.1":
                return "/accountHolder/" + savingPlanId.current;
            case "2.2":
                return "/suitabilityTestList/" + savingPlanId.current;
            case "2.3":
                return "/savingsPlans/" + savingPlanId.current + '/selectIdentities';
            case "2.4":
                return '/savingsPlans/' + savingPlanId.current + '/managementAuthorizationContract';
            case "2.5":
                return "/contract/" + savingPlanId.current;
            case "4.1":
                if (isExperiencePlan === true) {
                    return "/search";
                }
                if (isSaverPlan === true) {
                    return '/savingsPlans/iAmASaver/' + savingPlanId.current;
                }
                if (isAlchemyPlan === true) {
                    return '/savingsPlans/iLikeToDecide/' + savingPlanId.current;
                }
                if (isSponsoredPlan === true) {
                    return '/savingsPlans/iHaveExperience/' + savingPlanId.current;
                }

                return "/portfolio";
            case "4.2":
                return "/cart";
            case "4.3":
                return "/transfer";
            case "4.4":
                return "/distributor-switch";
        }
    }
    React.useEffect(() => {
        if (firstTimeLoading.current === true) {
            firstTimeLoading.current = false;
            savingPlanId.current = (savingPlanId.current !== undefined ? savingPlanId.current : savingPlan.id);
            savingsPlans.getMapConfigurationData(userId, savingPlanId.current).then((data: MapConfigurationData) => {
                setConfigurationMapData(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, savingPlan]);

    React.useEffect(() => {
        if (configurationMapData !== undefined) {
            setStepMap(configurationMapData.mapStep);
            setIsSponsoredPlan(configurationMapData.isSponsoredPlan);
            setIsExperiencePlan(configurationMapData.isExperiencePlan);
            setIsSaverPlan(configurationMapData.isSaverPlan);
            setIsAlchemyPlan(configurationMapData.isAlchemyPlan);
            setRepaymentObject(configurationMapData.repaymentObject);
            setIsMapEnabled(configurationMapData.isMapEnabled);

            if (configurationMapData.status === 1 && configurationMapData.revisionTime !== undefined && configurationMapData.revisionTime !== null) {
                setIsCheckingPlan(true);
                let revisionTime: string = getRevisionTime(configurationMapData.revisionTime);
                setTimeChecking(revisionTime);
            }
            switch (configurationMapData.status) {
                case 4:
                    setStatus("ACTIVO");
                    setStatusColor("green");
                    break;
                case 5:
                    setOutdatedPlan(true);
                    setLink("Repayment");
                    break;
            }
            switch (true) {
                case configurationMapData.mapStep.startsWith("1"):
                    setIsSubscriptionExpanded(true);
                    break;
                case configurationMapData.mapStep.startsWith("2"):
                    setIsTitularesExpanded(true);
                    break;
                case configurationMapData.mapStep.startsWith("3"):
                    setIsVerificationExpanded(true);
                    break;
                case configurationMapData.mapStep.startsWith("4"):
                    setIsPortfolioExpanded(true);
                    break;
            }

            setShowNext(configurationMapData.mapStep !== '1.1');
            setIsLoading(false);
        }
    }, [configurationMapData]);

    React.useEffect(() => {
        if (configurationMapData !== undefined && isAlchemyPlan !== undefined) {
            setLink(chooseLink(configurationMapData.mapStep));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlchemyPlan, configurationMapData]);

    const getRevisionTime = (revisionTime: string) => {
        if (revisionTime.indexOf(".") !== -1) {
            revisionTime = revisionTime.replace(".", ",")
        }

        let hourMin: string[] = revisionTime.split(",");
        let minutes: string = (parseInt(hourMin[1].substring(0, 2)) / 100 * 60).toString().substring(0, 2);

        return hourMin[0] + " H " + minutes + " min";
    }
    const onChange = (ev: any, checked?: boolean) => {
        if (checked !== undefined) {
            users.setConfigurationMap(userId, checked);
        }
    }
    const displayArrow = (expanded: boolean): JSX.Element => {
        switch (expanded) {
            case true:
                {
                    return <ChevronUpIcon className="tableArrow" />;
                }
            case false:
                {
                    return <ChevronDownIcon className="tableArrow" />;
                }
        }
    }
    const handleWindowResize = React.useCallback(event => {
        setWindowSize(window.innerWidth);

    }, []);
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    return (
        <React.Fragment>
            <DocumentTitle title="IronIA - Mapa de Configuración">
                <React.Fragment>
                    {isLoading === true &&
                        (
                            <React.Fragment>
                                <figure className='full-loading-image'>
                                    <div>
                                        <svg width="50%" viewBox="0 0 182 72" fill="none">
                                            <path d="M 36.375,27.1719 28.0521,40.9062 H 47.9863 L 45.7968,37.5225" stroke="#cc214f" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" id="path10" />
                                            <path d="m 43.1375,33.4593 -0.633,-0.9782 -0.8494,-1.278" stroke="#cc214f" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" id="path12" />
                                            <path d="m 40.7812,24.875 c 0,1.7086 -1.3851,3.0938 -3.0937,3.0938 -1.7086,0 -3.0937,-1.3852 -3.0937,-3.0938 0,-1.7086 1.3851,-3.0938 3.0937,-3.0938 1.7086,0 3.0937,1.3852 3.0937,3.0938 z m -4.0031,0 c 0,0.5023 0.4071,0.9094 0.9094,0.9094 0.5023,0 0.9094,-0.4071 0.9094,-0.9094 0,-0.5023 -0.4071,-0.9094 -0.9094,-0.9094 -0.5023,0 -0.9094,0.4071 -0.9094,0.9094 z" fill="#2a4143" id="path14" />
                                            <path d="M 61.2153,41.7401 V 22.2338 h 4.7805 v 19.5063 z" fill="#2a4143" id="path2" />
                                            <path d="m 82.3294,33.4259 3.3435,8.3031 H 80.4636 L 77.5661,34.3174 H 74.0205 V 41.729 H 69.2837 V 22.2513 h 9.8985 c 3.3155,0 6.0462,2.7028 6.0462,6.0184 -0.0027,1.0354 -0.2706,2.053 -0.7781,2.9556 -0.5074,0.9026 -1.2376,1.6602 -2.1209,2.2006 z M 80.4622,28.2711 C 80.4583,27.9324 80.322,27.6087 80.0826,27.3693 79.8431,27.1298 79.5194,26.9935 79.1807,26.9896 h -5.1602 v 2.5914 h 5.1617 c 0.6966,0 1.2814,-0.5853 1.2814,-1.3099 z" fill="#2a4143" id="path16" />
                                            <path d="m 106.626,31.9492 c 0.002,1.3103 -0.255,2.608 -0.756,3.8188 -0.501,1.2109 -1.235,2.311 -2.162,3.2376 -0.926,0.9265 -2.027,1.6612 -3.237,2.1621 -1.2112,0.5008 -2.5089,0.7579 -3.8192,0.7566 -5.4889,0 -9.975,-4.4582 -9.975,-9.9751 0,-5.4889 4.4837,-9.9751 9.975,-9.9751 5.5172,0 9.9742,4.4862 9.9742,9.9751 z m -4.736,0 c 0.001,-0.6881 -0.134,-1.3696 -0.397,-2.0055 -0.263,-0.6359 -0.649,-1.2136 -1.135,-1.7002 -0.487,-0.4866 -1.0648,-0.8724 -1.7007,-1.1354 -0.6359,-0.2629 -1.3174,-0.3979 -2.0055,-0.3971 -2.87,0 -5.2382,2.3407 -5.2382,5.2382 0,2.8975 2.3682,5.2382 5.2382,5.2382 0.6881,8e-4 1.3696,-0.1342 2.0055,-0.3972 0.6359,-0.2629 1.2137,-0.6488 1.7007,-1.1353 0.486,-0.4866 0.872,-1.0643 1.135,-1.7002 0.263,-0.6359 0.398,-1.3174 0.397,-2.0055 z" fill="#2a4143" id="path18" />
                                            <path d="M 108.96,41.7401 V 22.2338 h 4.093 l 8.407,11.9236 V 22.2338 h 4.588 v 19.5063 h -4.093 l -8.407,-11.9236 v 11.9236 z" fill="#2a4143" id="path4" />
                                            <path d="M 128.6,41.7402 V 22.2339 h 4.781 v 19.5063 z" fill="#cc214f" id="path6" />
                                            <path d="m 135.385,41.7402 7.72,-19.5063 h 5.083 l 7.72,19.5063 h -4.946 l -1.373,-3.7914 h -8.05 l -1.374,3.7914 z m 7.61,-7.8026 h 5.138 L 145.55,26.822 Z" fill="#cc214f" id="path8" />
                                            <text>
                                                <tspan id="tspan9286" x="60.555046" y="53.544037">TU PERSONAL STORE FINANCIERO</tspan>
                                            </text>
                                        </svg>
                                    </div>
                                </figure>
                                <Element.LoadingContainer>
                                    <Element.LoadingTitle>Cargando plan de cartera: {savingPlanName.current !== undefined ? savingPlanName.current : savingPlan.name}</Element.LoadingTitle>
                                </Element.LoadingContainer>
                            </React.Fragment>
                        )}
                    {isLoading === false &&
                        (
                            <React.Fragment>
                                <Element.PageTitle>
                                    Mapa de Configuración - {savingPlanName.current !== undefined ? savingPlanName.current : savingPlan.name}
                                </Element.PageTitle>
                                <Separator className="separator mt0 mb0" />
                                <Element.DivColRectangleSubscription className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl4 ms-xxxl4">
                                    {windowSize < 1024 &&
                                        (
                                            <React.Fragment>
                                                {outdatedPlan === false && stepMap.startsWith("1") &&
                                                    (
                                                        <React.Fragment>
                                                            {isSubscriptionExpanded === true &&
                                                                (
                                                                    <Element.FirstRectangleSmall onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1 - Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstRectangleSmall>
                                                                )}
                                                            {!isSubscriptionExpanded &&
                                                                (
                                                                    <Element.FirstRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1 - Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {outdatedPlan === false && !stepMap.startsWith("1") &&
                                                    (
                                                        <React.Fragment>
                                                            {isSubscriptionExpanded === true &&
                                                                (
                                                                    <Element.FirstDisabledRectangleSmall onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1- Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstDisabledRectangleSmall>
                                                                )}
                                                            {!isSubscriptionExpanded &&
                                                                (
                                                                    <Element.FirstDisabledRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1- Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstDisabledRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {outdatedPlan === true &&
                                                    (
                                                        <React.Fragment>
                                                            {isSubscriptionExpanded === true &&
                                                                (
                                                                    <Element.OutdatedRectangleSmall onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            Suscripción Caducada
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.OutdatedRectangleSmall>
                                                                )}
                                                            {!isSubscriptionExpanded &&
                                                                (
                                                                    <Element.OutdatedRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            Suscripción Caducada
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.OutdatedRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 &&
                                        (
                                            <React.Fragment>
                                                {outdatedPlan === false && stepMap.startsWith("1") &&
                                                    (
                                                        <React.Fragment>
                                                            {isSubscriptionExpanded === true &&
                                                                (
                                                                    <Element.FirstRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1 - Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstRectangle>
                                                                )}
                                                            {!isSubscriptionExpanded &&
                                                                (
                                                                    <Element.FirstRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1 - Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {outdatedPlan === false && !stepMap.startsWith("1") &&
                                                    (
                                                        <React.Fragment>
                                                            {isSubscriptionExpanded === true &&
                                                                (
                                                                    <Element.FirstDisabledRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1 - Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstDisabledRectangle>
                                                                )}
                                                            {!isSubscriptionExpanded &&
                                                                (
                                                                    <Element.FirstDisabledRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            1 - Suscripción
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.FirstDisabledRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {outdatedPlan === true &&
                                                    (
                                                        <React.Fragment>
                                                            {isSubscriptionExpanded === true &&
                                                                (
                                                                    <Element.OutdatedRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            Suscripción Caducada
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.OutdatedRectangle>
                                                                )}
                                                            {!isSubscriptionExpanded &&
                                                                (
                                                                    <Element.OutdatedRectangle onClick={() => {
                                                                        setIsSubscriptionExpanded(!isSubscriptionExpanded);
                                                                    }}>
                                                                        <Element.FirstTextRectangle>
                                                                            {displayArrow(isSubscriptionExpanded)}
                                                                            Suscripción Caducada
                                                                        </Element.FirstTextRectangle>
                                                                    </Element.OutdatedRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    {windowSize < 1024 && isSubscriptionExpanded === true &&
                                        (
                                            <React.Fragment>
                                                <Element.DivContainerSubscription className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 pr0">
                                                    <Element.RowPanel className="ms-Grid-row" theme={theme}>
                                                        <Element.SmallPanelContainer>
                                                            <Element.DivRowSmallPanel className="ms-Grid-row">
                                                                <Element.DivColFirstPanels className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                                                                    <Element.Panel theme={theme}>
                                                                        <Element.DivRow11Title className="ms-Grid-row">
                                                                            <Element.DivColFirstPanelTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg10 ms-xl9 ms-xxl10 ms-xxxl10">
                                                                                <Element.SmallTitle11>Elige Suscripción</Element.SmallTitle11>
                                                                            </Element.DivColFirstPanelTitle>
                                                                            <Element.DivColFirstPanelBadge className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 ms-xl3 ms-xxl2 ms-xxxl2">
                                                                                <Element.MiniBadge11 theme={theme}>1.1</Element.MiniBadge11>
                                                                            </Element.DivColFirstPanelBadge>
                                                                        </Element.DivRow11Title>
                                                                        <Element.DivRow11Text className="ms-Grid-row">
                                                                            <Element.DivColFirstPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInside11>
                                                                                    Selecciona la suscripción que más se ajuste a tus necesidades. Si has seleccionado un plan
                                                                                    <Element.BoldName> Plan Gestionado</Element.BoldName> o <Element.BoldName>Me Gusta Decidir</Element.BoldName>,
                                                                                    la suscripción es la del Plan.
                                                                                </Element.TextInside11>
                                                                            </Element.DivColFirstPanelText>
                                                                            {outdatedPlan === true &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        {stepMap === "1.1" &&
                                                                                            (
                                                                                                <Element.FirstContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                    <Element.ButtonRedirect11 onClick={() => history.push(
                                                                                                        {
                                                                                                            pathname: link,
                                                                                                            state:
                                                                                                            {
                                                                                                                subscriptionId: repaymentObject.subscriptionId,
                                                                                                                userSubscriptionId: repaymentObject.userSubscriptionId,
                                                                                                                subscriptionType: repaymentObject.type,
                                                                                                                limitAmount: repaymentObject.limitAmount
                                                                                                            }
                                                                                                        })}
                                                                                                    >
                                                                                                        Elige Suscripción
                                                                                                    </Element.ButtonRedirect11>
                                                                                                </Element.FirstContainerButton>
                                                                                            )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            {outdatedPlan === false &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        {stepMap === "1.1" &&
                                                                                            (
                                                                                                <Element.FirstContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                    <Element.ButtonRedirect11 onClick={() => history.push({ pathname: link })}>Elige Suscripción</Element.ButtonRedirect11>
                                                                                                </Element.FirstContainerButton>
                                                                                            )}
                                                                                        {stepMap !== "1.1" &&
                                                                                            (
                                                                                                <Element.FirstContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                    <Element.ButtonRedirectDisabled11 disabled={true}>Elige Suscripción</Element.ButtonRedirectDisabled11>
                                                                                                </Element.FirstContainerButton>
                                                                                            )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </Element.DivRow11Text>
                                                                    </Element.Panel>
                                                                </Element.DivColFirstPanels>
                                                                <Element.DivColSecondPanels className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                                                                    <Element.SecondPanel theme={theme}>
                                                                        <Element.DivRow12Title className="ms-Grid-row">
                                                                            <Element.DivColSecondPanelTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg10 ms-xl9 ms-xxl10 ms-xxxl10">
                                                                                <Element.SmallTitle12>Pago Periódico</Element.SmallTitle12>
                                                                            </Element.DivColSecondPanelTitle>
                                                                            <Element.DivColSecondPanelBadge className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 ms-xl3 ms-xxl2 ms-xxxl2">
                                                                                <Element.MiniBadge12 theme={theme}>1.2</Element.MiniBadge12>
                                                                            </Element.DivColSecondPanelBadge>
                                                                        </Element.DivRow12Title>
                                                                        <Element.DivRow12Text className="ms-Grid-row">
                                                                            <Element.DivColSecondPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInside12>
                                                                                    Selecciona tu banco y periódicamente tu suscripción se abonará automáticamente hasta que lo canceles.
                                                                                </Element.TextInside12>
                                                                            </Element.DivColSecondPanelText>
                                                                            {stepMap === "1.2" &&
                                                                                (
                                                                                    <Element.SecondContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirect12 onClick={() => history.push({ pathname: link })}>Elige Suscripción</Element.ButtonRedirect12>
                                                                                    </Element.SecondContainerButton>
                                                                                )}
                                                                            {stepMap !== "1.2" &&
                                                                                (
                                                                                    <Element.SecondContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirectDisabled12 disabled={true}>Elige Suscripción</Element.ButtonRedirectDisabled12>
                                                                                    </Element.SecondContainerButton>
                                                                                )}
                                                                        </Element.DivRow12Text>
                                                                    </Element.SecondPanel>
                                                                    <Element.BankPanel theme={theme}>
                                                                        <Element.DivRow12BTitle className="ms-Grid-row">
                                                                            <Element.DivColSecondBPanelTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg10 ms-xl9 ms-xxl10 ms-xxxl10">
                                                                                <Element.SmallTitle12B>Pago Puntual</Element.SmallTitle12B>
                                                                            </Element.DivColSecondBPanelTitle>
                                                                            <Element.DivColSecondBPanelBadge className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 ms-xl3 ms-xxl2 ms-xxxl2">
                                                                                <Element.MiniBadge12B theme={theme}>1.2</Element.MiniBadge12B>
                                                                            </Element.DivColSecondBPanelBadge>
                                                                        </Element.DivRow12BTitle>
                                                                        <Element.DivRow12BText className="ms-Grid-row">
                                                                            <Element.DivColSecondBPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInside12B>
                                                                                    Selecciona tu banco y paga solo un periodo determinado utilizando tus Elements.
                                                                                </Element.TextInside12B>
                                                                            </Element.DivColSecondBPanelText>
                                                                            {stepMap === "1.2" &&
                                                                                (
                                                                                    <Element.SecondBContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirect12B onClick={() => history.push({ pathname: link })}>Elige Suscripción</Element.ButtonRedirect12B>
                                                                                    </Element.SecondBContainerButton>
                                                                                )}
                                                                            {stepMap !== "1.2" &&
                                                                                (
                                                                                    <Element.SecondBContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirectDisabled12B disabled={true}>Elige Suscripción</Element.ButtonRedirectDisabled12B>
                                                                                    </Element.SecondBContainerButton>
                                                                                )}
                                                                        </Element.DivRow12BText>
                                                                    </Element.BankPanel>
                                                                </Element.DivColSecondPanels>
                                                            </Element.DivRowSmallPanel>
                                                        </Element.SmallPanelContainer>
                                                    </Element.RowPanel>
                                                </Element.DivContainerSubscription>
                                            </React.Fragment>
                                        )}
                                    {windowSize < 1024 &&
                                        (
                                            <React.Fragment>
                                                <Element.TogglePanelContainer>
                                                    <Element.DivRowTogglePanel className="ms-Grid-row">
                                                        <Element.ContainerCol12 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12" style={{ paddingTop: "10px" }}>
                                                            <Element.ConfigurationPanel theme={theme}>
                                                                <Element.DivRowToggleTitle className="ms-Grid-row">
                                                                    <Element.DivColToggleTitle className="ms-Grid-col ms-sm12 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                                                        <Element.SmallTitleToggle>Mapa de Configuración del Plan de Cartera</Element.SmallTitleToggle>
                                                                    </Element.DivColToggleTitle>
                                                                    <Element.DivColToggleToggle className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 rightData">
                                                                        <Element.ToggleStyled theme={theme} defaultChecked={isMapEnabled} onText="Sí" offText="No" onChange={onChange} />
                                                                    </Element.DivColToggleToggle>
                                                                </Element.DivRowToggleTitle>
                                                                <Element.DivRowToggleText className="ms-Grid-row">
                                                                    <Element.DivColToggleText className="ms-Grid-col ms-sm12 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                                                        <Element.TextInsideToggle>
                                                                            Con este mapa te resultará sencillo saber qué hacer en cada momento.
                                                                        </Element.TextInsideToggle>
                                                                    </Element.DivColToggleText>
                                                                    <Element.DivColToggleContainerButton className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 rightData">
                                                                        {stepMap === "3.1" &&
                                                                            (
                                                                                <Element.ButtonRedirectDisabledToggle>
                                                                                    Siguiente
                                                                                </Element.ButtonRedirectDisabledToggle>
                                                                            )}
                                                                        {stepMap !== "3.1" &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    {showNext === false &&
                                                                                        (
                                                                                            <Element.ButtonRedirectToggle onClick={() => history.push({ pathname: link })}>
                                                                                                Comenzar
                                                                                            </Element.ButtonRedirectToggle>
                                                                                        )}
                                                                                    {showNext === true &&
                                                                                        (
                                                                                            <Element.ButtonRedirectToggle onClick={() => history.push({ pathname: link })}>
                                                                                                Siguiente
                                                                                            </Element.ButtonRedirectToggle>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </Element.DivColToggleContainerButton>
                                                                </Element.DivRowToggleText>
                                                            </Element.ConfigurationPanel>
                                                        </Element.ContainerCol12>
                                                    </Element.DivRowTogglePanel>
                                                </Element.TogglePanelContainer>
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 && isSubscriptionExpanded === true &&
                                        (
                                            <React.Fragment>
                                                <Element.DivContainerSubscription className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 pr0">
                                                    <Element.RowPanel className="ms-Grid-row" theme={theme}>
                                                        <Element.SmallPanelContainer>
                                                            <Element.DivRowSmallPanel className="ms-Grid-row">
                                                                <Element.DivColFirstPanels className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                                                                    <Element.Panel theme={theme}>
                                                                        <Element.DivRow11Title className="ms-Grid-row">
                                                                            <Element.DivColFirstPanelTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg10 ms-xl9 ms-xxl10 ms-xxxl10">
                                                                                <Element.SmallTitle11>Elige Suscripción</Element.SmallTitle11>
                                                                            </Element.DivColFirstPanelTitle>
                                                                            <Element.DivColFirstPanelBadge className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 ms-xl3 ms-xxl2 ms-xxxl2">
                                                                                <Element.MiniBadge11 theme={theme}>1.1</Element.MiniBadge11>
                                                                            </Element.DivColFirstPanelBadge>
                                                                        </Element.DivRow11Title>
                                                                        <Element.DivRow11Text className="ms-Grid-row">
                                                                            <Element.DivColFirstPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInside11>
                                                                                    Selecciona la suscripción que más se ajuste a tus necesidades. Si has seleccionado un plan
                                                                                    <Element.BoldName> Plan Gestionado</Element.BoldName> o <Element.BoldName>Me Gusta Decidir</Element.BoldName>,
                                                                                    la suscripción es la del Plan.
                                                                                </Element.TextInside11>
                                                                            </Element.DivColFirstPanelText>
                                                                            {outdatedPlan === true &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        {stepMap === "1.1" &&
                                                                                            (
                                                                                                <Element.FirstContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                    <Element.ButtonRedirect11 onClick={() => history.push(
                                                                                                        {
                                                                                                            pathname: link,
                                                                                                            state:
                                                                                                            {
                                                                                                                subscriptionId: repaymentObject.subscriptionId,
                                                                                                                userSubscriptionId: repaymentObject.userSubscriptionId,
                                                                                                                subscriptionType: repaymentObject.type,
                                                                                                                limitAmount: repaymentObject.limitAmount
                                                                                                            }
                                                                                                        })}
                                                                                                    >
                                                                                                        Elige Suscripción
                                                                                                    </Element.ButtonRedirect11>
                                                                                                </Element.FirstContainerButton>
                                                                                            )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            {outdatedPlan === false &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        {stepMap === "1.1" &&
                                                                                            (
                                                                                                <Element.FirstContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                    <Element.ButtonRedirect11 onClick={() => history.push({ pathname: link })}>Elige Suscripción</Element.ButtonRedirect11>
                                                                                                </Element.FirstContainerButton>
                                                                                            )}
                                                                                        {stepMap !== "1.1" &&
                                                                                            (
                                                                                                <Element.FirstContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                    <Element.ButtonRedirectDisabled11 disabled={true}>Elige Suscripción</Element.ButtonRedirectDisabled11>
                                                                                                </Element.FirstContainerButton>
                                                                                            )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </Element.DivRow11Text>
                                                                    </Element.Panel>
                                                                </Element.DivColFirstPanels>
                                                                <Element.DivColSecondPanels className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                                                                    <Element.SecondPanel theme={theme}>
                                                                        <Element.DivRow12Title className="ms-Grid-row">
                                                                            <Element.DivColSecondPanelTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg10 ms-xl9 ms-xxl10 ms-xxxl10">
                                                                                <Element.SmallTitle12>Pago Periódico</Element.SmallTitle12>
                                                                            </Element.DivColSecondPanelTitle>
                                                                            <Element.DivColSecondPanelBadge className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 ms-xl3 ms-xxl2 ms-xxxl2">
                                                                                <Element.MiniBadge12 theme={theme}>1.2</Element.MiniBadge12>
                                                                            </Element.DivColSecondPanelBadge>
                                                                        </Element.DivRow12Title>
                                                                        <Element.DivRow12Text className="ms-Grid-row">
                                                                            <Element.DivColSecondPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInside12>
                                                                                    Selecciona tu banco y periódicamente tu suscripción se abonará automáticamente hasta que lo canceles.
                                                                                </Element.TextInside12>
                                                                            </Element.DivColSecondPanelText>
                                                                            {stepMap === "1.2" &&
                                                                                (
                                                                                    <Element.SecondContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirect12 onClick={() => history.push({ pathname: link })}>Elige Suscripción</Element.ButtonRedirect12>
                                                                                    </Element.SecondContainerButton>
                                                                                )}
                                                                            {stepMap !== "1.2" &&
                                                                                (
                                                                                    <Element.SecondContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirectDisabled12 disabled={true}>Elige Suscripción</Element.ButtonRedirectDisabled12>
                                                                                    </Element.SecondContainerButton>
                                                                                )}
                                                                        </Element.DivRow12Text>
                                                                    </Element.SecondPanel>
                                                                    <Element.BankPanel theme={theme}>
                                                                        <Element.DivRow12BTitle className="ms-Grid-row">
                                                                            <Element.DivColSecondBPanelTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg10 ms-xl9 ms-xxl10 ms-xxxl10">
                                                                                <Element.SmallTitle12B>Pago Puntual</Element.SmallTitle12B>
                                                                            </Element.DivColSecondBPanelTitle>
                                                                            <Element.DivColSecondBPanelBadge className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 ms-xl3 ms-xxl2 ms-xxxl2">
                                                                                <Element.MiniBadge12B theme={theme}>1.2</Element.MiniBadge12B>
                                                                            </Element.DivColSecondBPanelBadge>
                                                                        </Element.DivRow12BTitle>
                                                                        <Element.DivRow12BText className="ms-Grid-row">
                                                                            <Element.DivColSecondBPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInside12B>
                                                                                    Selecciona tu banco y paga solo un periodo determinado utilizando tus Elements.
                                                                                </Element.TextInside12B>
                                                                            </Element.DivColSecondBPanelText>
                                                                            {stepMap === "1.2" &&
                                                                                (
                                                                                    <Element.SecondBContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirect12B onClick={() => history.push({ pathname: link })}>Elige Suscripción</Element.ButtonRedirect12B>
                                                                                    </Element.SecondBContainerButton>
                                                                                )}
                                                                            {stepMap !== "1.2" &&
                                                                                (
                                                                                    <Element.SecondBContainerButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                        <Element.ButtonRedirectDisabled12B disabled={true}>Elige Suscripción</Element.ButtonRedirectDisabled12B>
                                                                                    </Element.SecondBContainerButton>
                                                                                )}
                                                                        </Element.DivRow12BText>
                                                                    </Element.BankPanel>
                                                                </Element.DivColSecondPanels>
                                                            </Element.DivRowSmallPanel>
                                                        </Element.SmallPanelContainer>
                                                    </Element.RowPanel>
                                                </Element.DivContainerSubscription>
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 &&
                                        (
                                            <React.Fragment>
                                                <Element.DivRowTogglePanel className="ms-Grid-row" >
                                                    <Element.ContainerCol12 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12" style={{ paddingTop: "10px" }}>
                                                        <Element.ConfigurationPanel theme={theme}>
                                                            <Element.DivRowToggleTitle className="ms-Grid-row">
                                                                <Element.DivColToggleTitle className="ms-Grid-col ms-sm12 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                                                    <Element.SmallTitleToggle>Mapa de Configuración del Plan de Cartera</Element.SmallTitleToggle>
                                                                </Element.DivColToggleTitle>
                                                                <Element.DivColToggleToggle className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 rightData">
                                                                    <Element.ToggleStyled theme={theme} defaultChecked={isMapEnabled} onText="Sí" offText="No" onChange={onChange} />
                                                                </Element.DivColToggleToggle>
                                                            </Element.DivRowToggleTitle>
                                                            <Element.DivRowToggleText className="ms-Grid-row">
                                                                <Element.DivColToggleText className="ms-Grid-col ms-sm12 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                                                    <Element.TextInsideToggle>
                                                                        Con este mapa te resultará sencillo saber qué hacer en cada momento.
                                                                    </Element.TextInsideToggle>
                                                                </Element.DivColToggleText>
                                                                <Element.DivColToggleContainerButton className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 rightData">
                                                                    {stepMap === "3.1" &&
                                                                        (
                                                                            <Element.ButtonRedirectDisabledToggle>
                                                                                Siguiente
                                                                            </Element.ButtonRedirectDisabledToggle>
                                                                        )}
                                                                    {stepMap !== "3.1" &&
                                                                        (
                                                                            <React.Fragment>
                                                                                {showNext === false &&
                                                                                    (
                                                                                        <Element.ButtonRedirectToggle onClick={() => history.push({ pathname: link })}>
                                                                                            Comenzar
                                                                                        </Element.ButtonRedirectToggle>
                                                                                    )}
                                                                                {showNext === true &&
                                                                                    (
                                                                                        <Element.ButtonRedirectToggle onClick={() => history.push({ pathname: link })}>
                                                                                            Siguiente
                                                                                        </Element.ButtonRedirectToggle>
                                                                                    )}
                                                                            </React.Fragment>
                                                                        )}
                                                                </Element.DivColToggleContainerButton>
                                                            </Element.DivRowToggleText>
                                                        </Element.ConfigurationPanel>
                                                    </Element.ContainerCol12>
                                                </Element.DivRowTogglePanel>
                                            </React.Fragment>
                                        )}
                                </Element.DivColRectangleSubscription>
                                <Element.DivColRectangleTitulares className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl3 ms-xxxl3">
                                    <Element.SecondRectangle style={{ backgroundColor: stepMap === "2.1" ? '#CC214' : '#908B8B' }}>
                                        <Element.SecondTextRectangle>
                                            2- Intervinientes
                                        </Element.SecondTextRectangle>
                                    </Element.SecondRectangle>
                                    <Element.DivContainerTitulares className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12" style={{ clear: 'both' }}>
                                        <Element.SecondRowPanel className="ms-Grid-row" theme={theme}>
                                            <Element.PanelContainer2>
                                                <Element.DivColContainer2 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                    <Element.BigPanelCard21 theme={theme}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                <Element.SmallTitle21>Añadir Interviniente</Element.SmallTitle21>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                <Element.MiniBadge21 theme={theme}>2.1</Element.MiniBadge21>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.TextInside21 className="pt0">
                                                                    En este paso puedes <strong>añadir</strong> o <strong>modificar</strong> todos los intervinientes que desees.
                                                                </Element.TextInside21>
                                                                <Element.TextInside21 className="pt0">
                                                                    Recuerda que necesitas tener preparado para cada interviniente:
                                                                </Element.TextInside21>
                                                                <ul style={{ padding: '0 1em 0 2em', listStyle: 'disc', fontSize: '16px', marginTop: '-10px' }}>
                                                                    <li>Foto del DNI, pasaporte, etc. Anverso y reverso.</li>
                                                                    <li>Justificante de ingresos. Declaración de la Renta, Herencias, etc.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <Element.DivRowImages className="ms-Grid-row">
                                                            <Element.DivContainerButton21 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                <Element.MiniButton21 id={windowSize < 1024 ? '' : 'tooltipClassToggle21'} onClick={() => history.push({ pathname: stepMap === "2.1" ? link : chooseLink("2.1") })} onMouseEnter={showTooltipClass21}>
                                                                    {stepMap === "2.1" ? 'Ir' : 'Añadir - Modificar'}
                                                                </Element.MiniButton21>
                                                                {windowSize < 1024 &&
                                                                    (
                                                                        <img
                                                                            // @ts-ignore
                                                                            src={`/icons/${theme?.palette.name === 'light' ? 'informationBlack' : 'informationWhite'}.svg`}
                                                                            id='tooltipClassToggle21'
                                                                            onClick={showTooltipClass21}
                                                                            alt="Información"
                                                                            style={{ marginLeft: '10px', top: '5px', position: 'relative' }}
                                                                        />
                                                                    )}
                                                            </Element.DivContainerButton21>
                                                        </Element.DivRowImages>
                                                    </Element.BigPanelCard21>
                                                </Element.DivColContainer2>
                                            </Element.PanelContainer2>
                                        </Element.SecondRowPanel>
                                    </Element.DivContainerTitulares>
                                    <Element.SecondRectangle style={{ clear: 'both', backgroundColor: stepMap.startsWith("2") ? '#CC214' : '#908B8B' }} onClick={() => { setIsTitularesExpanded(!isTitularesExpanded) }}>
                                        <Element.SecondTextRectangle>
                                            {displayArrow(isTitularesExpanded)}
                                            Intervinientes. Más pasos...
                                        </Element.SecondTextRectangle>
                                    </Element.SecondRectangle>
                                    {isTitularesExpanded === true &&
                                        (
                                            <Element.DivContainerTitulares className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                <Element.SecondRowPanel className="ms-Grid-row" theme={theme}>
                                                    <Element.PanelContainer2>
                                                        <Element.DivColContainer2 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.PanelCard22 theme={theme}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallTitle22>Test de Idoneidad Interviniente</Element.SmallTitle22>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadge22 theme={theme}>2.2</Element.MiniBadge22>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInside22>
                                                                            Puedes realizar el test de idoneidad de los intervinientes que desees.
                                                                        </Element.TextInside22>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row">
                                                                    <React.Fragment>
                                                                        {(isSaverPlan === true || isAlchemyPlan === true) &&
                                                                            (
                                                                                <Element.DivContainerButton22 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton22 onClick={() => history.push({ pathname: chooseLink("2.2") })}>Completar Test</Element.MiniButton22>
                                                                                </Element.DivContainerButton22>
                                                                            )}
                                                                        {(isSaverPlan === false && isAlchemyPlan === false) &&
                                                                            (
                                                                                <Element.DivContainerButton22 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton22Disabled disabled={true}>Ir</Element.MiniButton22Disabled>
                                                                                </Element.DivContainerButton22>
                                                                            )}
                                                                    </React.Fragment>
                                                                </div>
                                                            </Element.PanelCard22>
                                                            <Element.PanelCard23 theme={theme}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallTitle23>Seleccionar Intervinientes</Element.SmallTitle23>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadge23 theme={theme}>2.3</Element.MiniBadge23>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm9 ms-md10 ms-lg10 ms-xl10 ms-xxl10 ms-xxxl10">
                                                                        <Element.TextInside23>
                                                                            Aquí puedes seleccionar el rol de cada uno de los intervinientes.
                                                                        </Element.TextInside23>
                                                                    </div>
                                                                    {stepMap === "2.3" &&
                                                                        (
                                                                            <Element.DivContainerButton23 className="ms-Grid-col ms-sm3 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 rightData">
                                                                                <Element.MiniButton23 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton23>
                                                                            </Element.DivContainerButton23>
                                                                        )}
                                                                    {stepMap !== "2.3" &&
                                                                        (
                                                                            <Element.DivContainerButton23 className="ms-Grid-col ms-sm3 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 rightData">
                                                                                <Element.MiniButton23Disabled disabled={true}>Ir</Element.MiniButton23Disabled>
                                                                            </Element.DivContainerButton23>
                                                                        )}
                                                                </div>
                                                            </Element.PanelCard23>
                                                            <Element.BigPanelCard24 theme={theme}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallTitle24>¿Si el usuario no es Interviniente del Contrato?</Element.SmallTitle24>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadge24 theme={theme}>2.4</Element.MiniBadge24>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm9 ms-md10 ms-lg10 ms-xl10 ms-xxl10 ms-xxxl10">
                                                                        <Element.TextInside24>
                                                                            El resto de los intervinientes deben firmar la autorización de gestión en la plataforma.
                                                                        </Element.TextInside24>
                                                                    </div>
                                                                    {stepMap === "2.4" &&
                                                                        (
                                                                            <Element.DivContainerButton24 className="ms-Grid-col ms-sm3 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 rightData">
                                                                                <Element.MiniButton24 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton24>
                                                                            </Element.DivContainerButton24>
                                                                        )}
                                                                    {stepMap !== "2.4" &&
                                                                        (
                                                                            <Element.DivContainerButton24 className="ms-Grid-col ms-sm3 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 rightData">
                                                                                <Element.MiniButton24Disabled disabled={true}>Ir</Element.MiniButton24Disabled>
                                                                            </Element.DivContainerButton24>
                                                                        )}
                                                                </div>
                                                            </Element.BigPanelCard24>
                                                            <Element.PanelCard25 theme={theme}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallTitle25>Firma Contrato cada Interviniente</Element.SmallTitle25>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadge25 theme={theme}>2.5</Element.MiniBadge25>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm9 ms-md10 ms-lg10 ms-xl10 ms-xxl10 ms-xxxl10">
                                                                        <Element.TextInside25>
                                                                            Cada interviniente debe firmar con su teléfono de firma.
                                                                        </Element.TextInside25>
                                                                    </div>
                                                                    {stepMap === "2.5" &&
                                                                        (
                                                                            <Element.DivContainerButton25 className="ms-Grid-col ms-sm3 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 rightData">
                                                                                <Element.MiniButton25 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton25>
                                                                            </Element.DivContainerButton25>
                                                                        )}
                                                                    {stepMap !== "2.5" &&
                                                                        (
                                                                            <Element.DivContainerButton25 className="ms-Grid-col ms-sm3 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 rightData">
                                                                                <Element.MiniButton25Disabled disabled={true}>Ir</Element.MiniButton25Disabled>
                                                                            </Element.DivContainerButton25>
                                                                        )}
                                                                </div>
                                                            </Element.PanelCard25>
                                                        </Element.DivColContainer2>
                                                    </Element.PanelContainer2>
                                                </Element.SecondRowPanel>
                                            </Element.DivContainerTitulares>
                                        )}
                                </Element.DivColRectangleTitulares>
                                <Element.DivColRectangleVerification className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl2 ms-xxxl2">
                                    {windowSize < 1024 &&
                                        (
                                            <React.Fragment>
                                                {stepMap.startsWith("3") &&
                                                    (
                                                        <React.Fragment>
                                                            {isVerificationExpanded === true &&
                                                                (
                                                                    <Element.ThirdRectangleSmall onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdRectangleSmall>
                                                                )}
                                                            {!isVerificationExpanded &&
                                                                (
                                                                    <Element.ThirdRectangle onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {!stepMap.startsWith("3") &&
                                                    (
                                                        <React.Fragment>
                                                            {isVerificationExpanded === true &&
                                                                (
                                                                    <Element.ThirdDisabledRectangleSmall onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdDisabledRectangleSmall>
                                                                )}
                                                            {!isVerificationExpanded &&
                                                                (
                                                                    <Element.ThirdDisabledRectangle onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdDisabledRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 &&
                                        (
                                            <React.Fragment>
                                                {stepMap.startsWith("3") &&
                                                    (
                                                        <React.Fragment>
                                                            {isVerificationExpanded === true &&
                                                                (
                                                                    <Element.ThirdRectangle onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdRectangle>
                                                                )}
                                                            {!isVerificationExpanded &&
                                                                (
                                                                    <Element.ThirdRectangle onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {!stepMap.startsWith("3") &&
                                                    (
                                                        <React.Fragment>
                                                            {isVerificationExpanded === true &&
                                                                (
                                                                    <Element.ThirdDisabledRectangle onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdDisabledRectangle>
                                                                )}
                                                            {!isVerificationExpanded &&
                                                                (
                                                                    <Element.ThirdDisabledRectangle onClick={() => {
                                                                        setIsVerificationExpanded(!isVerificationExpanded);
                                                                    }}>
                                                                        <Element.ThirdTextRectangle>
                                                                            {displayArrow(isVerificationExpanded)}
                                                                            3 - Verificar
                                                                        </Element.ThirdTextRectangle>
                                                                    </Element.ThirdDisabledRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    {windowSize < 1024 && isVerificationExpanded === true &&
                                        (
                                            <React.Fragment>
                                                <Element.DivContainerVerification className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                    <Element.ThirdRowPanel className="ms-Grid-row" theme={theme}>
                                                        <Element.PanelContainer3>
                                                            <Element.DivColContainer3 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.SmallPanel theme={theme}>
                                                                    <Element.DivRow31Title className="ms-Grid-row">
                                                                        <Element.DivCol31Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl10">
                                                                            <Element.SmallTitle31>Verificamos</Element.SmallTitle31>
                                                                        </Element.DivCol31Title>
                                                                        <Element.DivCol31Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl2">
                                                                            <Element.MiniBadge31 theme={theme}>3.1</Element.MiniBadge31>
                                                                        </Element.DivCol31Badge>
                                                                    </Element.DivRow31Title>
                                                                    <Element.DivRow31Text className="ms-Grid-row">
                                                                        <Element.DivCol31Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside31>
                                                                                IronIA Fintech verifica toda la información y, si todo está correcto,
                                                                                procede a crear el contrato.
                                                                            </Element.TextInside31>
                                                                        </Element.DivCol31Text>
                                                                    </Element.DivRow31Text>
                                                                </Element.SmallPanel>
                                                            </Element.DivColContainer3>
                                                            {isCheckingPlan === true &&
                                                                (
                                                                    <Element.DivColRevisionPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.StatusPanelTime theme={theme}>
                                                                            <Element.DivRowRevisionTimeTitle className="ms-Grid-row">
                                                                                <Element.DivColRevisionTimeTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                    <Element.SmallTitleRevision>En Revisión</Element.SmallTitleRevision>
                                                                                </Element.DivColRevisionTimeTitle>
                                                                            </Element.DivRowRevisionTimeTitle>
                                                                            <Element.DivRowRevisionTimeText className="ms-Grid-row">
                                                                                <Element.DivColRevisionTimeTime className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                    <Element.TimeChecking>
                                                                                        <Element.TimeCheckingText>{timeChecking}</Element.TimeCheckingText>
                                                                                    </Element.TimeChecking>
                                                                                </Element.DivColRevisionTimeTime>
                                                                            </Element.DivRowRevisionTimeText>
                                                                        </Element.StatusPanelTime>
                                                                    </Element.DivColRevisionPanel>
                                                                )}
                                                            <Element.DivColSavingPlanContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.StatusPanelSavingPlan theme={theme}>
                                                                    <Element.DivRowSavingPlanTitle className="ms-Grid-row">
                                                                        <Element.DivColSavingPlanTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                                                            <Element.SmallTitleSavingPlan>Plan de Cartera</Element.SmallTitleSavingPlan>
                                                                        </Element.DivColSavingPlanTitle>
                                                                    </Element.DivRowSavingPlanTitle>
                                                                    <Element.DivRowSavingPlanText className="ms-Grid-row">
                                                                        <Element.DivColSavingPlanText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.StatusPlan style={{ backgroundColor: statusColor, border: "2px solid " + statusColor }}>
                                                                                <Element.StatusPlanText>{status}</Element.StatusPlanText>
                                                                            </Element.StatusPlan>
                                                                        </Element.DivColSavingPlanText>
                                                                    </Element.DivRowSavingPlanText>
                                                                </Element.StatusPanelSavingPlan>
                                                            </Element.DivColSavingPlanContainer>
                                                        </Element.PanelContainer3>
                                                    </Element.ThirdRowPanel>
                                                </Element.DivContainerVerification>
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 && isVerificationExpanded === true &&
                                        (
                                            <React.Fragment>
                                                <Element.DivContainerVerification className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                    <Element.ThirdRowPanel className="ms-Grid-row" theme={theme}>
                                                        <Element.PanelContainer3>
                                                            <Element.DivColContainer3 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.SmallPanel theme={theme}>
                                                                    <Element.DivRow31Title className="ms-Grid-row">
                                                                        <Element.DivCol31Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl10">
                                                                            <Element.SmallTitle31>Verificamos</Element.SmallTitle31>
                                                                        </Element.DivCol31Title>
                                                                        <Element.DivCol31Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl2">
                                                                            <Element.MiniBadge31 theme={theme}>3.1</Element.MiniBadge31>
                                                                        </Element.DivCol31Badge>
                                                                    </Element.DivRow31Title>
                                                                    <Element.DivRow31Text className="ms-Grid-row">
                                                                        <Element.DivCol31Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside31>
                                                                                IronIA Fintech verifica toda la información y, si todo está correcto,
                                                                                procede a crear el contrato.
                                                                            </Element.TextInside31>
                                                                        </Element.DivCol31Text>
                                                                    </Element.DivRow31Text>
                                                                </Element.SmallPanel>
                                                            </Element.DivColContainer3>
                                                            {isCheckingPlan === true &&
                                                                (
                                                                    <Element.DivColRevisionPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.StatusPanelTime theme={theme}>
                                                                            <Element.DivRowRevisionTimeTitle className="ms-Grid-row">
                                                                                <Element.DivColRevisionTimeTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                    <Element.SmallTitleRevision>En Revisión</Element.SmallTitleRevision>
                                                                                </Element.DivColRevisionTimeTitle>
                                                                            </Element.DivRowRevisionTimeTitle>
                                                                            <Element.DivRowRevisionTimeText className="ms-Grid-row">
                                                                                <Element.DivColRevisionTimeTime className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                    <Element.TimeChecking>
                                                                                        <Element.TimeCheckingText>{timeChecking}</Element.TimeCheckingText>
                                                                                    </Element.TimeChecking>
                                                                                </Element.DivColRevisionTimeTime>
                                                                            </Element.DivRowRevisionTimeText>
                                                                        </Element.StatusPanelTime>
                                                                    </Element.DivColRevisionPanel>
                                                                )}
                                                            <Element.DivColSavingPlanContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.StatusPanelSavingPlan theme={theme}>
                                                                    <Element.DivRowSavingPlanTitle className="ms-Grid-row">
                                                                        <Element.DivColSavingPlanTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                                                            <Element.SmallTitleSavingPlan>Plan de Cartera</Element.SmallTitleSavingPlan>
                                                                        </Element.DivColSavingPlanTitle>
                                                                    </Element.DivRowSavingPlanTitle>
                                                                    <Element.DivRowSavingPlanText className="ms-Grid-row">
                                                                        <Element.DivColSavingPlanText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.StatusPlan style={{ backgroundColor: statusColor, border: "2px solid " + statusColor }}>
                                                                                <Element.StatusPlanText>{status}</Element.StatusPlanText>
                                                                            </Element.StatusPlan>
                                                                        </Element.DivColSavingPlanText>
                                                                    </Element.DivRowSavingPlanText>
                                                                </Element.StatusPanelSavingPlan>
                                                            </Element.DivColSavingPlanContainer>
                                                        </Element.PanelContainer3>
                                                    </Element.ThirdRowPanel>
                                                </Element.DivContainerVerification>
                                            </React.Fragment>
                                        )}
                                </Element.DivColRectangleVerification>
                                <Element.DivColRectanglePortfolio className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl3 ms-xxxl3">
                                    {windowSize < 1024 &&
                                        (
                                            <React.Fragment>
                                                {stepMap.startsWith("4") &&
                                                    (
                                                        <React.Fragment>
                                                            {isPortfolioExpanded === true &&
                                                                (
                                                                    <Element.FourthRectangleSmall onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthRectangleSmall>
                                                                )}
                                                            {!isPortfolioExpanded &&
                                                                (
                                                                    <Element.FourthRectangle onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {!stepMap.startsWith("4") &&
                                                    (
                                                        <React.Fragment>
                                                            {isPortfolioExpanded === true &&
                                                                (
                                                                    <Element.FourthDisabledRectangleSmall onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthDisabledRectangleSmall>
                                                                )}
                                                            {!isPortfolioExpanded &&
                                                                (
                                                                    <Element.FourthDisabledRectangle onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthDisabledRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 &&
                                        (
                                            <React.Fragment>
                                                {stepMap.startsWith("4") &&
                                                    (
                                                        <React.Fragment>
                                                            {isPortfolioExpanded === true &&
                                                                (
                                                                    <Element.FourthRectangle onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthRectangle>
                                                                )}
                                                            {!isPortfolioExpanded &&
                                                                (
                                                                    <Element.FourthRectangle onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                                {!stepMap.startsWith("4") &&
                                                    (
                                                        <React.Fragment>
                                                            {isPortfolioExpanded === true &&
                                                                (
                                                                    <Element.FourthDisabledRectangle onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthDisabledRectangle>
                                                                )}
                                                            {!isPortfolioExpanded &&
                                                                (
                                                                    <Element.FourthDisabledRectangle onClick={() => {
                                                                        setIsPortfolioExpanded(!isPortfolioExpanded);
                                                                    }}>
                                                                        <Element.FourthTextRectangle>
                                                                            {displayArrow(isPortfolioExpanded)}
                                                                            4 - Crear Cartera
                                                                        </Element.FourthTextRectangle>
                                                                    </Element.FourthDisabledRectangle>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    {windowSize < 1024 && isPortfolioExpanded === true &&
                                        (
                                            <React.Fragment>
                                                <Element.DivContainerPortfolio className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                    <Element.LastRowPanel theme={theme} className="ms-Grid-row">
                                                        <Element.PanelContainer4>
                                                            <Element.DivColContainer4 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.CartPanel theme={theme}>
                                                                    <Element.DivRow41Title className="ms-Grid-row">
                                                                        <Element.DivCol41Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle41>Seleccionar Producto</Element.SmallTitle41>
                                                                        </Element.DivCol41Title>
                                                                        <Element.DivCol41Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1 rightData">
                                                                            <Element.MiniBadge41 theme={theme}>4.1</Element.MiniBadge41>
                                                                        </Element.DivCol41Badge>
                                                                    </Element.DivRow41Title>
                                                                    <Element.DivRow41Text className="ms-Grid-row">
                                                                        <Element.DivCol41Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside41>
                                                                                Introduce los productos y determina su cantidad en el carrito de compra. Si has
                                                                                seleccionado un plan <Element.BoldName>Plan Gestionado</Element.BoldName> o <Element.BoldName>Me Gusta Decidir </Element.BoldName>
                                                                                los productos aparecerán automáticamente.
                                                                            </Element.TextInside41>
                                                                        </Element.DivCol41Text>
                                                                        {stepMap === "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton41 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl2 ms-xxxl2 rightData">
                                                                                    <Element.MiniButton41 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton41>
                                                                                </Element.DivContainerButton41>
                                                                            )}
                                                                        {stepMap !== "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton41 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl2 ms-xxxl2 rightData">
                                                                                    <Element.MiniButton41Disabled disabled={true}>Ir</Element.MiniButton41Disabled>
                                                                                </Element.DivContainerButton41>
                                                                            )}
                                                                    </Element.DivRow41Text>
                                                                </Element.CartPanel>
                                                                <Element.SmallCartPanel theme={theme}>
                                                                    <Element.DivRow42Title className="ms-Grid-row">
                                                                        <Element.DivCol42Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle42>Abonar Carrito</Element.SmallTitle42>
                                                                        </Element.DivCol42Title>
                                                                        <Element.DivCol42Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1 rightData">
                                                                            <Element.MiniBadge42 theme={theme}>4.2</Element.MiniBadge42>
                                                                        </Element.DivCol42Badge>
                                                                    </Element.DivRow42Title>
                                                                    <Element.DivRow42Text className="ms-Grid-row">
                                                                        <Element.DivCol42Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside42>
                                                                                Firma la orden de compra con tu teléfono móvil y paga el importe del carrito por
                                                                                transferencia PSD2 desde la cuenta corriente de tu banco.
                                                                            </Element.TextInside42>
                                                                        </Element.DivCol42Text>
                                                                        {stepMap === "4.2" &&
                                                                            (
                                                                                <Element.DivContainerButton42 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton42 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton42>
                                                                                </Element.DivContainerButton42>
                                                                            )}
                                                                        {stepMap !== "4.2" &&
                                                                            (
                                                                                <Element.DivContainerButton42 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton42Disabled disabled={true}>Ir</Element.MiniButton42Disabled>
                                                                                </Element.DivContainerButton42>
                                                                            )}
                                                                    </Element.DivRow42Text>
                                                                </Element.SmallCartPanel>
                                                                <Element.ExtraSmallPanel theme={theme}>
                                                                    <Element.DivRow43Title className="ms-Grid-row">
                                                                        <Element.DivCol43Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle43>Traspaso Externo</Element.SmallTitle43>
                                                                        </Element.DivCol43Title>
                                                                        <Element.DivCol43Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                            <Element.MiniBadge43 theme={theme}>4.3</Element.MiniBadge43>
                                                                        </Element.DivCol43Badge>
                                                                    </Element.DivRow43Title>
                                                                    <Element.DivRow43Text className="ms-Grid-row">
                                                                        <Element.DivCol43Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside43>
                                                                                Puedes traspasar a tu cuenta fondos de otras entidades.
                                                                            </Element.TextInside43>
                                                                        </Element.DivCol43Text>
                                                                        {stepMap === "4.1" &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    {(isExperiencePlan === true || isSponsoredPlan === true) &&
                                                                                        (
                                                                                            <Element.DivContainerButton43 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton43 onClick={() => history.push({ pathname: "/transfer" })}>Ir</Element.MiniButton43>
                                                                                            </Element.DivContainerButton43>
                                                                                        )}
                                                                                    {(isExperiencePlan === false && isSponsoredPlan === false) &&
                                                                                        (
                                                                                            <Element.DivContainerButton43 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton43Disabled disabled={true}>Ir</Element.MiniButton43Disabled>
                                                                                            </Element.DivContainerButton43>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        {stepMap !== "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton43 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton43Disabled disabled={true}>Ir</Element.MiniButton43Disabled>
                                                                                </Element.DivContainerButton43>
                                                                            )}
                                                                    </Element.DivRow43Text>
                                                                </Element.ExtraSmallPanel>
                                                                <Element.ExtraSmallPanel theme={theme}>
                                                                    <Element.DivRow44Title className="ms-Grid-row">
                                                                        <Element.DivCol44Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle44>Cambio de Comercializador</Element.SmallTitle44>
                                                                        </Element.DivCol44Title>
                                                                        <Element.DivCol44Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1 rightData">
                                                                            <Element.MiniBadge44 theme={theme}>4.4</Element.MiniBadge44>
                                                                        </Element.DivCol44Badge>
                                                                    </Element.DivRow44Title>
                                                                    <Element.DivRow44Text className="ms-Grid-row">
                                                                        <Element.DivCol44Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside44>
                                                                                Puedes traspasarte a tu cuenta fondos de otra entidad sin que dejen de cotizar ningún día.
                                                                            </Element.TextInside44>
                                                                        </Element.DivCol44Text>
                                                                        {stepMap === "4.1" &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    {(isExperiencePlan === true || isSponsoredPlan === true) &&
                                                                                        (
                                                                                            <Element.DivContainerButton44 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton44 onClick={() => history.push({ pathname: "/distributor-switch" })}>Ir</Element.MiniButton44>
                                                                                            </Element.DivContainerButton44>
                                                                                        )}
                                                                                    {(isExperiencePlan === false && isSponsoredPlan === false) &&
                                                                                        (
                                                                                            <Element.DivContainerButton44 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton44Disabled disabled={true}>Ir</Element.MiniButton44Disabled>
                                                                                            </Element.DivContainerButton44>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        {stepMap !== "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton44 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton44Disabled disabled={true}>Ir</Element.MiniButton44Disabled>
                                                                                </Element.DivContainerButton44>
                                                                            )}
                                                                    </Element.DivRow44Text>
                                                                </Element.ExtraSmallPanel>
                                                            </Element.DivColContainer4>
                                                        </Element.PanelContainer4>
                                                    </Element.LastRowPanel>
                                                </Element.DivContainerPortfolio>
                                            </React.Fragment>
                                        )}
                                    {windowSize >= 1024 && isPortfolioExpanded === true &&
                                        (
                                            <React.Fragment>
                                                <Element.DivContainerPortfolio className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                    <Element.LastRowPanel theme={theme} className="ms-Grid-row">
                                                        <Element.PanelContainer4>
                                                            <Element.DivColContainer4 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.CartPanel theme={theme}>
                                                                    <Element.DivRow41Title className="ms-Grid-row">
                                                                        <Element.DivCol41Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle41>Seleccionar Producto</Element.SmallTitle41>
                                                                        </Element.DivCol41Title>
                                                                        <Element.DivCol41Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1 rightData">
                                                                            <Element.MiniBadge41 theme={theme}>4.1</Element.MiniBadge41>
                                                                        </Element.DivCol41Badge>
                                                                    </Element.DivRow41Title>
                                                                    <Element.DivRow41Text className="ms-Grid-row">
                                                                        <Element.DivCol41Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside41>
                                                                                Introduce los productos y determina su cantidad en el carrito de compra. Si has
                                                                                seleccionado un plan <Element.BoldName>Plan Gestionado</Element.BoldName> o <Element.BoldName>Me Gusta Decidir </Element.BoldName>
                                                                                los productos aparecerán automáticamente.
                                                                            </Element.TextInside41>
                                                                        </Element.DivCol41Text>
                                                                        {stepMap === "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton41 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl2 ms-xxxl2 rightData">
                                                                                    <Element.MiniButton41 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton41>
                                                                                </Element.DivContainerButton41>
                                                                            )}
                                                                        {stepMap !== "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton41 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl2 ms-xxxl2 rightData">
                                                                                    <Element.MiniButton41Disabled disabled={true}>Ir</Element.MiniButton41Disabled>
                                                                                </Element.DivContainerButton41>
                                                                            )}
                                                                    </Element.DivRow41Text>
                                                                </Element.CartPanel>
                                                                <Element.SmallCartPanel theme={theme}>
                                                                    <Element.DivRow42Title className="ms-Grid-row">
                                                                        <Element.DivCol42Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle42>Abonar Carrito</Element.SmallTitle42>
                                                                        </Element.DivCol42Title>
                                                                        <Element.DivCol42Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1 rightData">
                                                                            <Element.MiniBadge42 theme={theme}>4.2</Element.MiniBadge42>
                                                                        </Element.DivCol42Badge>
                                                                    </Element.DivRow42Title>
                                                                    <Element.DivRow42Text className="ms-Grid-row">
                                                                        <Element.DivCol42Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside42>
                                                                                Firma la orden de compra con tu teléfono móvil y paga el importe del carrito por
                                                                                transferencia PSD2 desde la cuenta corriente de tu banco.
                                                                            </Element.TextInside42>
                                                                        </Element.DivCol42Text>
                                                                        {stepMap === "4.2" &&
                                                                            (
                                                                                <Element.DivContainerButton42 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton42 onClick={() => history.push({ pathname: link })}>Ir</Element.MiniButton42>
                                                                                </Element.DivContainerButton42>
                                                                            )}
                                                                        {stepMap !== "4.2" &&
                                                                            (
                                                                                <Element.DivContainerButton42 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton42Disabled disabled={true}>Ir</Element.MiniButton42Disabled>
                                                                                </Element.DivContainerButton42>
                                                                            )}
                                                                    </Element.DivRow42Text>
                                                                </Element.SmallCartPanel>
                                                                <Element.ExtraSmallPanel theme={theme}>
                                                                    <Element.DivRow43Title className="ms-Grid-row">
                                                                        <Element.DivCol43Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle43>Traspaso Externo</Element.SmallTitle43>
                                                                        </Element.DivCol43Title>
                                                                        <Element.DivCol43Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                            <Element.MiniBadge43 theme={theme}>4.3</Element.MiniBadge43>
                                                                        </Element.DivCol43Badge>
                                                                    </Element.DivRow43Title>
                                                                    <Element.DivRow43Text className="ms-Grid-row">
                                                                        <Element.DivCol43Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside43>
                                                                                Puedes traspasar a tu cuenta fondos de otras entidades.
                                                                            </Element.TextInside43>
                                                                        </Element.DivCol43Text>
                                                                        {stepMap === "4.1" &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    {(isExperiencePlan === true || isSponsoredPlan === true) &&
                                                                                        (
                                                                                            <Element.DivContainerButton43 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton43 onClick={() => history.push({ pathname: "/transfer" })}>Ir</Element.MiniButton43>
                                                                                            </Element.DivContainerButton43>
                                                                                        )}
                                                                                    {(isExperiencePlan === false && isSponsoredPlan === false) &&
                                                                                        (
                                                                                            <Element.DivContainerButton43 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton43Disabled disabled={true}>Ir</Element.MiniButton43Disabled>
                                                                                            </Element.DivContainerButton43>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        {stepMap !== "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton43 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton43Disabled disabled={true}>Ir</Element.MiniButton43Disabled>
                                                                                </Element.DivContainerButton43>
                                                                            )}
                                                                    </Element.DivRow43Text>
                                                                </Element.ExtraSmallPanel>
                                                                <Element.ExtraSmallPanel theme={theme}>
                                                                    <Element.DivRow44Title className="ms-Grid-row">
                                                                        <Element.DivCol44Title className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallTitle44>Cambio de Comercializador</Element.SmallTitle44>
                                                                        </Element.DivCol44Title>
                                                                        <Element.DivCol44Badge className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1 rightData">
                                                                            <Element.MiniBadge44 theme={theme}>4.4</Element.MiniBadge44>
                                                                        </Element.DivCol44Badge>
                                                                    </Element.DivRow44Title>
                                                                    <Element.DivRow44Text className="ms-Grid-row">
                                                                        <Element.DivCol44Text className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInside44>
                                                                                Puedes traspasarte a tu cuenta fondos de otra entidad sin que dejen de cotizar ningún día.
                                                                            </Element.TextInside44>
                                                                        </Element.DivCol44Text>
                                                                        {stepMap === "4.1" &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    {(isExperiencePlan === true || isSponsoredPlan === true) &&
                                                                                        (
                                                                                            <Element.DivContainerButton44 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton44 onClick={() => history.push({ pathname: "/distributor-switch" })}>Ir</Element.MiniButton44>
                                                                                            </Element.DivContainerButton44>
                                                                                        )}
                                                                                    {(isExperiencePlan === false && isSponsoredPlan === false) &&
                                                                                        (
                                                                                            <Element.DivContainerButton44 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                                <Element.MiniButton44Disabled disabled={true}>Ir</Element.MiniButton44Disabled>
                                                                                            </Element.DivContainerButton44>
                                                                                        )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        {stepMap !== "4.1" &&
                                                                            (
                                                                                <Element.DivContainerButton44 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                    <Element.MiniButton44Disabled disabled={true}>Ir</Element.MiniButton44Disabled>
                                                                                </Element.DivContainerButton44>
                                                                            )}
                                                                    </Element.DivRow44Text>
                                                                </Element.ExtraSmallPanel>
                                                            </Element.DivColContainer4>
                                                        </Element.PanelContainer4>
                                                    </Element.LastRowPanel>
                                                </Element.DivContainerPortfolio>
                                            </React.Fragment>
                                        )}
                                </Element.DivColRectanglePortfolio>
                                {tooltipClassToggle21 === true &&
                                    (
                                        <TeachingBubble target="#tooltipClassToggle21"
                                            primaryButtonProps={step21PrimaryButtonProps}
                                            secondaryButtonProps={step21SecondaryButtonProps}
                                            footerContent={`${tooltipStep21 + 1} de 4`}
                                            onDismiss={hideTooltipClass21}
                                            calloutProps={{ backgroundColor: 'white !important', style: { color: 'red' } }}
                                            hasCloseButton={true}
                                            closeButtonAriaLabel="Cerrar"
                                            styles={
                                                {
                                                    bodyContent: { '.ms-Icon': { color: '#555 !important' } },
                                                    body:
                                                    {
                                                        background: 'white',
                                                        marginRight: '0',
                                                        '.TipStep': { transition: 'all 0.5s ease-in-out 0s', opacity: '1' },
                                                        '.hidden': { opacity: '0', display: 'inline-block !important', height: '0', width: '0', overflow: 'hidden' }
                                                    },
                                                    subText: { color: '#555 !important', 'p': { margin: '5px 0' } },
                                                    footer: { color: '#555 !important' }
                                                }}
                                        >
                                            <div className='header'>
                                                <LightbulbIcon style={{ color: 'gold', width: '20px', height: '20px' }} />
                                                <h3 style={{ display: 'inline', verticalAlign: 'middle', marginLeft: '5px' }}>Tips</h3>
                                            </div>
                                            <div className={`TipStep ${tooltipStep21 === 0 ? '' : 'hidden'}`}>
                                                <img src='./images/tips/step21/holders.webp' alt='' style={{ margin: '5px 0', width: '100%' }} />
                                                <h4>Intervinientes</h4>
                                                <p>En esta sección puedes introducir los datos de todas las personas que intervengan en un contrato.</p>
                                                <p>Posteriormente decidirás cuales son las que participan y con qué papel lo hacen.</p>
                                                <p>Puedes tener intervinientes que no participen en algún contrato.</p>
                                            </div>
                                            <div className={`TipStep ${tooltipStep21 === 1 ? '' : 'hidden'}`}>
                                                <img src='./images/tips/step21/documents.webp' alt='' style={{ margin: '5px 0', width: '100%' }} />
                                                <h4>Documentos de Identificación</h4>
                                                <p>Necesitas tener dos archivos con tu documento de identificación: anverso y reverso en formato de imagen jpg, bmp, etc. o documento de Acrobat Reader, pdf.</p>
                                                <p>Si tienes un solo archivo con anverso y reverso puedes utilizarlo dos veces.</p>
                                            </div>
                                            <div className={`TipStep ${tooltipStep21 === 2 ? '' : 'hidden'}`}>
                                                <img src='./images/tips/step21/income.webp' alt='' style={{ margin: '5px 0', width: '100%' }} />
                                                <h4>Justificante de Ingresos</h4>
                                                <p>Necesitamos saber de dónde proviene el dinero con el que vas a adquirir los productos financieros.</p>
                                                <p>Este justificante puede ser la declaración de la renta, una declaración de herencia, etc.</p>
                                                <p>Las facturas son gastos, no son válidas como justificante de ingresos.</p>
                                            </div>
                                            <div className={`TipStep ${tooltipStep21 === 3 ? '' : 'hidden'}`}>
                                                <img src='./images/tips/step21/convenience.webp' alt='' style={{ margin: '5px 0', width: '100%' }} />
                                                <h4>Conocer a nuestro cliente</h4>
                                                <p>Para cada interviniente realizaremos unas sencillas preguntas para determinar si puede realizar inversiones. Recuerda, estas preguntas te protegen sobre posibles fraudes.</p>
                                            </div>
                                        </TeachingBubble>
                                    )}
                            </React.Fragment>
                        )}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}