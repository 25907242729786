import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { UserIdentityType } from "./UserIdentitiesService";
import { ISignature } from "./SignaturesService";
import { PortfolioType } from "./PortfolioService";
const proxy: string | undefined = config.proxy_url;
setupInterceptorsTo(axios);

export enum OrderOrderType {
    "Subscription",
    "Refund",
    "InternalSwitch",
    "ExternalSwitch",
    "MarketerSwitch",
    "PeriodicSubscription"
}

export const OrderStatuses = new Map([
    ["PENDING", "Procesando"],
    ["SENT", "Enviada"],
    ["ERROR", "Error"],
    ["WARNING", "Aviso"],
    ["VALIDATED", "Validada"],
    ["FINISHED", "Finalizado"],
    ["CANCELED", "Cancelada"],
    ["COMPLETED", "Completada"],
    ["PENDING_PAYMENT", "Pendiente"]
]);

export const OrderTypes = new Map([
    ["subscription", "Suscripción"],
    ["internal_switch", "Traspaso interno"],
    ["external_switch", "Traspaso externo"],
    ["refund", "Reembolso"]
]);

interface IOrderValues
{
    type: number | string,                 
    amountType: string,         
    amount: number,
    fund?: string
}

export type OrderValuesType = IOrderValues;

interface ISubscriptionValues extends IOrderValues { }

export type SubscriptionValuesType = ISubscriptionValues;

interface IInternalSwitchValues extends IOrderValues
{
    fundName: string,
    fundIsin: string,
    newInstrument: string,
    newInstrumentName: string
}

export type InternalSwitchValuesType = IInternalSwitchValues;

interface InceptionFund {
    company: string,
    name: string,
    isin: string,
    account: string,
    entityNif: string
}

interface IExternalSwitchValues extends IOrderValues {
    newInstrument: string,
    newInstrumentName: string,
    inceptionFund: InceptionFund
}

export type ExternalSwitchValuesType = IExternalSwitchValues;

interface IRefundValues extends IOrderValues {
    fundName?: string,
    fundIsin?: string,
    iban?: string
}

export type RefundValuesType = IRefundValues;

interface IFixIncidenceValues extends IOrderValues {
    fixedOrder: number,
    fixedOrderType: number,
    fixedOrderFinametrixId: number,
    iban?: string
}

interface ICancelValues extends IOrderValues {
    amount: number,
    amountType: string,
}


export type FixIncidenceValuesType = IFixIncidenceValues;

interface IOrder {
    id?: number,
    finametrixId?: string,
    notes?: string,
    userIdentityId?: number,
    userIdentity?: UserIdentityType,
    fnmPortfolioId?: number,
    fnmPortfolio?: PortfolioType,
    instrumentId?: string,
    signatureId?: number,
    signatureCancelId?: number,
    signature?: ISignature,
    signatureCancel?: ISignature,
    orderType: number,
    status: number,
    incidence: boolean,
    source?: string,
    values?: ISubscriptionValues | IInternalSwitchValues | IExternalSwitchValues | IRefundValues | IFixIncidenceValues | ICancelValues,
    createdAt: string,
    updatedAt?: string,
    paymentId?: number,
}

export type OrderType = IOrder;

export type OrderTrendByFundResponse =
{
    totalRecords: number,
    fundsTends: OrderTrendByFund[]
}

export interface IOrderTrendByFund
{
	fondo: string;
    allfundsId: string;
    isin: string;
    benchmarkFinametrixId: string;
    morningStarCategoryId: string;
	semanaCompras?: number;
	semanaTraspasosDestino?: number;
	semanaTraspasosOrigen?: number;
	semanaReembolsos?: number;
	mesCompras?: number;
	mesTraspasosDestino?: number;
	mesTraspasosOrigen?: number;
	mesReembolsos?: number;
	trimestreCompras?: number;
	trimestreTraspasosDestino?: number;
	trimestreTraspasosOrigen?: number;
	trimestreReembolsos?: number;
	añoCompras?: number;
	añoTraspasosDestino?: number;
	añoTraspasosOrigen?: number;
	añoReembolsos?: number;
	compras?: number;
	traspasosDestino?: number;
	traspasosOrigen?: number;
	reembolsos?: number;
};

export type OrderTrendByFund = IOrderTrendByFund;

export interface CartItemToValidate {
    allFundsId: string,
    amount: number
}

export interface ValidateAmountsResultItem {
    allFundsId: string,
    error: string,
    roundedAmount: number
}

export interface ValidateAmountsResult {
    error: boolean,
    fields: ValidateAmountsResultItem[]
}
class OrderService
{
    public getOrders = async (params: any): Promise<OrderType[]> =>
    {
        let url: string = `${proxy}/orders`
        const req = await axios.get<any>(url, { params: params });
        return req.data;
    }

    public getOrder = async (orderId: number): Promise<OrderType> =>
    {
        let url: string = `${proxy}/orders/${orderId}`
        const req = await axios.get<any>(url);
        return req.data;
    }

    public getOrderByFinametrixId = async (finametrixId: string): Promise<OrderType> => {
        let url: string = `${proxy}/ordersByFinametrixId/${finametrixId}`
        const req = await axios.get<any>(url);
        return req.data;
    }

    public createOrder = async (order: OrderType): Promise<OrderType> =>
    {
        let url: string = `${proxy}/orders`
        const req = await axios.post(url, order);
        return req.data;
    }
    public createOrderFromCancel = async (order: OrderType, orderId: any): Promise<OrderType> =>
    {
        let url: string = `${proxy}/order/cancel/create-order/${orderId}`
        const req = await axios.post(url, order);
        return req.data;
    }

    public checkRefundCode = async(model: any): Promise<boolean> =>
    {
        let url: string = `${proxy}/CheckRefundCode`
        const req = await axios.post(url, model);
        return req.data;
    }

    public createMultipleOrders = async (data: any): Promise<any> =>
    {
        let url: string = `${proxy}/CreateMultipleOrders`
        const req = await axios.post(url, data);
        return req.data;
    }

    public createOrderWithAttachment = async (order: OrderType): Promise<OrderType> =>
    {
        let url: string = `${proxy}/orders`
        let formData = new FormData();
        let data: any = {};
        Object.keys(order).forEach((field) =>
        {
            // @ts-ignore
            if(order[field]?.size !== undefined)
            {
                // @ts-ignore
                formData.append(field, order[field]);
            }
            else
            {
                // @ts-ignore
                data[field] = order[field];
            }
        });

        formData.append("data", JSON.stringify(data));
        const req = await axios.post(url, formData,
        {
            headers: {'content-type': 'multipart/form-data'},
        });
        
        return req.data;
    }

    public updateOrder = async (order: OrderType): Promise<OrderType> =>
    {
        let url: string = `${proxy}/orders/${order.id}`
        const req = await axios.put(url, order);
        return req.data;
    }

    public patchOrder = async (order: any): Promise<OrderType> =>
    {
        let url: string = `${proxy}/orders/${order.id}`
        const req = await axios.patch(url, order);
        return req.data;
    }

    public patchCancelOrder = async (order: any): Promise<OrderType> =>
    {
        let url: string = `${proxy}/order/cancel/signature/${order}`
        const req = await axios.patch(url, null);
        return req.data;
    }

    public signedCancelOrder = async (order: any, typeOfCancelation: number): Promise<any> =>
    {
        let url: string = `${proxy}/order/cancel/signed/${typeOfCancelation}/${order.finametrixId}`
        const req = await axios.patch(url, order);
        return req.data;
    }
    public signedCancelOrderManualNotification = async (order: any): Promise<any> =>
    {
        let url: string = `${proxy}/order/cancel/manual-notification/${order.finametrixId}`
        const req = await axios.patch(url, order);
        return req.data;
    }
    
    public inespayCancelOrden = async (order: any): Promise<any> =>
    {
        let url: string = `${proxy}/inespay/cancel-order/${order.id}`
        const req = await axios.post(url, null);
        return req.data;
    }

    public subscriptionOrdersValidateAmounts = async (orders: CartItemToValidate[]): Promise<ValidateAmountsResult> => {
        let url: string = `${proxy}/orders/subscription/validateAmounts`
        const req = await axios.post(url, orders);
        return req.data;
    }

    public createSubscriptionOrders = async (orders: any[]): Promise<ISignature> =>
    {
        let url: string = `${proxy}/orders/subscription`
        const req = await axios.post(url, orders);
        return req.data;
    }

    public patchSubscriptionOrders = async (orders: any[]): Promise<OrderType[]> =>
    {
        let url: string = `${proxy}/orders/subscription`
        const req = await axios.patch(url, orders);
        return req.data;
    }

    public createInternalTransfersOrders = async (orders: OrderType[]): Promise<ISignature> =>
    {
        let url: string = `${proxy}/orders/internalTransfers`
        const req = await axios.post(url, orders);
        return req.data;
    }

    public patchInternalTransfersOrders = async (signatureId: number): Promise<any> =>
    {
        let url: string = `${proxy}/orders/internalTransfers?signatureId=${signatureId}`
        const req = await axios.patch(url);
        return req.data;
    }

    public patchFixIncidenceOrder = async (order: any): Promise<OrderType> => {
        let url: string = `${proxy}/orders/fixIncidence`
        const req = await axios.patch(url, order);
        return req.data;
    }

    public getOrderTrendByFund = async (): Promise<OrderTrendByFundResponse> =>
    {
        let url: string = `${proxy}/orders/trend-by-fund`
        const req = await axios.get<any>(url);
        return req.data;
    }

    public updateDisclaimerTime = async (disclaimerObject: any): Promise<boolean> =>
    {
        let url: string = `${proxy}/UpdateDisclaimerTime`
        const req = await axios.patch(url, disclaimerObject);
        return req.data;
    }
}

const orders = new OrderService();

export default orders;