import * as S from '../ConvenienceTest.style';
import ConvenienceTestAccountHolderDataInputs from './ConvenienceTestAccountHolderDataInputs';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext } from 'react';
import Auth from "../../../Auth/Auth";
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';
import './dropDown.css';
import { useParams } from 'react-router-dom';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import SavingsPlanService, { SavingsPlanType } from '../../../services/SavingsPlanService';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestAccountHolderData = () => {
    const history = useHistory();
    const user = Auth.getUserProfile();
    const theme = useContext(ThemeContext);
    const [canSubmit, setCanSubmit] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState({ status: false, message: '' });
    const { savingsPlanId } = useParams<any>();
    const { userIdentityId } = useParams<any>();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [isLoadedUserIdentity, setIsLoadedUserIdentity] = useState(false);
    const [userIdentity, setUserIdentity] = useState<any>();
    const [sameAddress, setSameAddress] = useState<boolean>();
    const [userIdentityForm, setUserIdentityForm] = useState<any>(
        {
            firstName: false,
            lastName: false,
            email: false,
            operationCountryPrefix: true,
            countryPrefix: true,
            operationPhone: false,
            phone: false,
            birthday: true,
            idDocument: false,
            proofOfMinorGuardianDescription: true,
            postal_streetName: true,
            postal_streetNumber: true,
            postal_zipCode: false,
        });

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (userIdentityId !== null && userIdentityId !== undefined) {
            UserIdentitiesService.getUserIdentity(userIdentityId).then((userIdentity: UserIdentityType) => {
                setUserIdentity(userIdentity);
            },
                (error) => {
                    setError(error);
                });
        }
        else {
            UserIdentitiesService.getIdentitiesByUserId(user.id).then((userIdentities: UserIdentityType[]) => {
                if (userIdentities.length === 1 && userIdentities[0].updatedAt === null) {
                    setUserIdentity(userIdentities[0]);
                }
                else {
                    let userIdentityAux =
                    {
                        firstName: "",
                        lastName: "",
                        birthday: "",
                        email: "",
                        countryPrefix: "+34",
                        phone: "",
                        operationCountryPrefix: "+34",
                        birthdayCountry: "ES",
                        nationalCountry: "ES",
                        operationPhone: "",
                        userId: user.id,
                        status: "NEW",
                        fiscalAddress: null,
                        sameAddress: sameAddress ? true : false,
                        main: false,
                    };
                    setUserIdentity(userIdentityAux);
                }
            },
                (error: any) => {
                    setError(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdentityId]);

    useEffect(() => {
        if (savingsPlanId !== undefined) {
            SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingPlan: any) => {
                setSavingsPlan(savingPlan);
            },
                (error: any) => {
                    setError(error);
                });
        }
    }, [savingsPlanId]);

    useEffect(() => {
        if (userIdentity !== undefined) {
            setIsLoadedUserIdentity(true);
        }
    }, [userIdentity]);

    const handleAccountHolderDataChange = (event: any) => {
        let { name, value }: { name: string, value: string } = event.target;
        let realValue = value;
        if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            realValue = event.target.files[0];
        }
        setUserIdentity((prevState: any) =>
        ({
            ...prevState,
            [name]: realValue
        }));
    };

    const handlePostalAddressAccountHolderDataChange = (event: any) => {
        let { name, value }: { name: string, value: string } = event.target;
        let realValue = value;
        const realName = name.trim().startsWith('postal_') ? name.trim().slice(7) : name;

        if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            realValue = event.target.files[0];
        }

        setUserIdentity((prevState: any) =>
        ({
            ...prevState,
            postalAddress: {
                ...prevState.postalAddress,
                [realName]: realValue
            },
        }));
    };

    const handleAccountHolderSameAddressChange = (event: any) => {
        var { checked }: { name: string, checked: boolean } = event.target;
        setSameAddress(!checked);
        setUserIdentity((prevState: any) =>
        ({
            ...prevState,
            sameAddress: checked
        }));
    };

    const handleFiscalAddressAccountHolderDataChange = (event: any) => {
        let { name, value }: { name: string, value: string } = event.target;
        const realName = name.startsWith('fiscal_') ? name.slice(7) : name;
        let realValue = value;
        if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            realValue = event.target.files[0];
        }
        setUserIdentity((prevState: any) =>
        ({
            ...prevState,
            fiscalAddress: {
                ...prevState.fiscalAddress,
                [realName]: realValue
            },

        }));
    };

    const btnSubmit = useCallback(() => {
        userIdentityForm.birthday = true;
        const result = Object.values(userIdentityForm).every((ob) => ob === true);
        // console.log('[CAMBIOS] userIdentityForm: ', userIdentityForm);
        setCanSubmit(result);
    }, [userIdentityForm]);

    useEffect(() => {
        btnSubmit();
    }, [btnSubmit]);

    const updateUserIdentityForm = (name: string, value: any) => {
        if (userIdentityForm[name] !== value) {
            userIdentityForm[name] = value;
            setUserIdentityForm(userIdentityForm);
            btnSubmit();
        }
    }
    const updateWithIntelligence = (updatedData: any) => {
        setUserIdentity((prevState: any) => ({
            ...prevState,
            ...updatedData
        }));
    };

    const handleSubmit = () => {
        if (userIdentity !== undefined) {
            setSending(true);

            if (userIdentity.phone.startsWith(userIdentity.countryPrefix)) {
                userIdentity.phone = userIdentity.phone.replace(userIdentity.countryPrefix, '');
            }

            if (userIdentity.operationPhone.startsWith(userIdentity.operationCountryPrefix)) {
                userIdentity.operationPhone = userIdentity.operationPhone.replace(userIdentity.operationCountryPrefix, '');
            }

            if (userIdentity.signPhone === undefined) {
                userIdentity.signPhone = userIdentity.phone;
                userIdentity.signCountryPrefix = userIdentity.countryPrefix;
            }

            if (userIdentity.id !== undefined) {
                UserIdentitiesService.updateUserIdentity(userIdentity).then(() => {
                    history.push('/finance/' + savingsPlanId + '/' + userIdentity.id);
                },
                    (error: any) => {
                        setError(error);
                    });
            }
            else {
                console.log('[ENVIAR] useridentity: ', userIdentity);
                UserIdentitiesService.createUserIdentity(userIdentity).then((storedUserIdentityAux: UserIdentityType) => {
                    if (storedUserIdentityAux.id !== undefined) {
                        history.push('/finance/' + savingsPlanId + '/' + storedUserIdentityAux.id);
                    }
                },
                    (error: any) => {
                        setError(error);
                    });

            }
        }
    };

    return (
        <DocumentTitle title={`IronIA - Editar Interviniente`}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.MainCont>
                        <S.Steps>
                            <h3>Paso 1 <span> de 2</span></h3>
                        </S.Steps>
                        <S.Title>
                            <h1>Intervinientes</h1>
                            <h2>Aquí puedes gestionar los distintos tipos de intervinientes de tus planes de cartera.</h2>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        <S.TabletCont theme={theme}>
                            {!isLoadedUserIdentity &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                            {isLoadedUserIdentity &&
                                (
                                    <ConvenienceTestAccountHolderDataInputs
                                        data={userIdentity}
                                        handleChange={(event: any) => handleAccountHolderDataChange(event)}
                                        handlePostalAddrChange={(event: any) => handlePostalAddressAccountHolderDataChange(event)}
                                        handleFiscalAddrChange={(event: any) => handleFiscalAddressAccountHolderDataChange(event)}
                                        handleSameAddressChange={(event: any) => handleAccountHolderSameAddressChange(event)}
                                        updateIntelligenceUserIdentity={updateWithIntelligence}
                                        formVal={userIdentityForm}
                                        setFormVal={(name: string, value: any) => updateUserIdentityForm(name, value)}
                                    />
                                )}
                        </S.TabletCont>
                        {error.message !== "" &&
                            (
                                <S.TabletCont theme={theme}>
                                    <S.Error>Se ha producido un error que impide continuar con el proceso 2</S.Error>
                                </S.TabletCont>
                            )}
                        <S.BtnCont>
                            {savingsPlan === undefined && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                            {savingsPlan !== undefined &&
                                (
                                    <DefaultButton className='button tiny-secondary-button'
                                        onClick={() => history.push(`/accountHolder/${savingsPlanId}`)}
                                    >
                                        Volver
                                    </DefaultButton>
                                )}
                            <DefaultButton type='submit'
                                onClick={() => handleSubmit()}
                                disabled={!isLoadedUserIdentity || sending || !canSubmit}
                                className="button tiny-primary-button"
                            >
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestAccountHolderData;