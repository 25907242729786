var config: any = {};

switch(process.env.NODE_ENV)
{
    case "production":
    {
        config =
        {
            analysis_service: "#{ANALYSIS_SERVICE}#",
            cdn_url: "#{CDN_URL}#",
            clarity_key: "#{CLARITY_KEY}#",
            fid_functions_balance_code: "#{FID_FUNCTIONS_BALANCE_CODE}#",
            fid_functions_url: "#{FID_FUNCTIONS_URL}#",
            funds_service: "#{FUNDS_SERVICE}#",
            maintenance_mode: "#{MAINTENANCE_MODE}#",
            maintenance_url: "#{MAINTENANCE_URL}#",
            optimizer_litterman_code: "#{OPTIMIZER_LITTERMAN_CODE}#",
            optimizer_markowitz_code: "#{OPTIMIZER_MARKOWITZ_CODE}#",
            optimizer_url: "#{OPTIMIZER_URL}#",
            powerbi_api_reporttokens_code: "#{POWERBI_API_REPORTTOKENS_CODE}#",
            powerbi_service_url: "#{POWERBI_SERVICE_URL}#",
            proxy_url: "#{PROXY_URL}#",
            server_host: "#{SERVER_HOST}#",
            type_of_cancel_devolution: "#{TYPE_OF_CANCEL_DEVOLUTION}"
        };
        break;
    }
    default:
    {
        config =
        {
            analysis_service: process.env.REACT_APP_ANALYSIS_SERVICE,
            cdn_url: process.env.REACT_APP_CDN,
            clarity_key: "",
            fid_functions_balance_code:  process.env.REACT_APP_FID_FUNCTIONS_BALANCE_CODE,
            fid_functions_url:  process.env.REACT_APP_FID_FUNCTIONS_URL,
            funds_service: process.env.REACT_APP_FUNDS_SERVICE,
            maintenance_mode: process.env.REACT_APP_MAINTENANCE_MODE,
            maintenance_url: process.env.REACT_APP_MAINTENANCE_URL,
            optimizer_litterman_code:  process.env.REACT_APP_OPTIMIZER_LITTERMAN_CODE,
            optimizer_markowitz_code: process.env.REACT_APP_OPTIMIZER_MARKOWITZ_CODE,
            optimizer_url: process.env.REACT_APP_OPTIMIZER_URL,
            powerbi_api_reporttokens_code: process.env.REACT_APP_POWERBI_API_REPORTTOKENS_CODE,
            powerbi_service_url: process.env.REACT_APP_POWERBI_SERVICE_URL,
            proxy_url: process.env.REACT_APP_PROXY_URL,
            server_host: process.env.REACT_APP_SERVER_HOST,
            type_of_cancel_devolution: process.env.TYPE_OF_CANCEL_DEVOLUTION
        };
        break;
    }
}

if(config.maintenance_mode !== undefined && config.maintenance_mode === "true" && config.maintenance_url !== undefined)
{
    window.location.href = config.maintenance_url;
}

export default config;