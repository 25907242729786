export interface AddressModel {
    id?: number | null;
    roadType: string | '';
    addressType: string | '';
    streetName: string | '';
    streetNumber: string | '';
    streetFloor: string | '';
    streetStair: string | '';
    streetDoor: string | '';
    streetOther: string | '';
    city: string | '';
    zipCode: string | '';
    state: string | '';
    country: string | '';
}

export const setFullNameFunction = (data: any) => {
    let firstName = data.firstName;
    let lastName = '';
    let secondLastName = '';
    const lastNameParts = data.lastName.trim().split(/\s+/);
    if (lastNameParts.length > 0) {
        lastName = lastNameParts[0];
    }
    if (lastNameParts.length > 1) {
        secondLastName = lastNameParts.slice(1).join(' ');
    }

    return {
        firstName,
        lastName,
        secondLastName
    };
};

export const toPascalCase = (str: string) => {
    return str
        .toLowerCase()
        .replace(/(?:^|\s)(\w)/g, (match) => match.toUpperCase());
};

export const getIdType = (idType: string, countryRegion: string): string => {
    switch (idType) {
        case "idDocument.nationalIdentityCard":
            if (countryRegion === '' || countryRegion === 'ES' || countryRegion === null || countryRegion === undefined) {
                return 'DNI';
            } else {
                return 'NIE';
            }
        case "idDocument.passport":
            return 'PASSPORT';
        default:
            return 'DNI';
    }
};

export const parseAddressToModel = (addressString: string): AddressModel => {
    const lines = addressString.split('\n');
    const addressModel: AddressModel = {
        id: undefined,
        roadType: '',
        addressType: '',
        streetName: '',
        streetNumber: '',
        streetFloor: '',
        streetStair: '',
        streetDoor: '',
        streetOther: '',
        city: '',
        zipCode: '',
        state: '',
        country: ''
    };

    if (lines.length >= 1) {
        const streetLine = lines[0];
        const parts = streetLine.split(' ');

        // Tipo de vía (asumimos que es la primera parte)
        addressModel.roadType = parts[0];

        // Nombre de la calle (todo hasta el primer número)
        let streetNameEndIndex = parts.findIndex(part => /^\d+/.test(part));
        if (streetNameEndIndex === -1) streetNameEndIndex = parts.length;
        addressModel.streetName = parts.slice(1, streetNameEndIndex).join(' ');

        // Procesamos el resto de la información
        const restParts = parts.slice(streetNameEndIndex);
        for (let i = 0; i < restParts.length; i++) {
            const part = restParts[i];
            if (/^\d+/.test(part)) {
                // Número de la calle
                addressModel.streetNumber = part;
            } else if (/^P\d+/.test(part)) {
                // Piso
                addressModel.streetFloor = part.substring(1);
            } else if (/^[A-Z]$/.test(part)) {
                // Puerta o escalera
                if (addressModel.streetStair === '') {
                    addressModel.streetStair = part;
                } else {
                    addressModel.streetDoor = part;
                }
            } else {
                // Otros detalles
                addressModel.streetOther += (addressModel.streetOther ? ' ' : '') + part;
            }
        }
    }

    if (lines.length >= 2) {
        addressModel.city = lines[1].trim();
    }

    if (lines.length >= 3) {
        addressModel.state = lines[2].trim();
    }

    return addressModel;
};