import * as S from "../ConvenienceTest.style";
import { useContext, useEffect, useState } from "react";
import Moment from "moment";
import UserIdentitiesService, { UserIdentityType } from "../../../services/UserIdentitiesService";
import SavingsPlans, { SavingsPlanType } from "../../../services/SavingsPlanService";
import Auth from "../../../Auth/Auth";
import { useHistory, useParams } from "react-router-dom";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import scrollToTop from "../../../utils/scrollToTop";
import React from "react";
import SavingsPlanService from "../../../services/SavingsPlanService";
import DocumentTitle from "react-document-title";
import ModalAddExistingAccountHolder from "../ModalAddExistingAccountHolder";
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestAccountHolder = () => {
	const history = useHistory();
	const theme = useContext(ThemeContext);
	const [error, setError] = useState({ status: false, message: "" });
	const [size, setSize] = useState(window.innerWidth);
	const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
	const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const user = Auth.getUserProfile();
	const { savingsPlanId } = useParams<any>();
	const [showExistingAccountModal, setShowExistingAccountModal] = useState<boolean>(false);

	useEffect(() => {
		scrollToTop();
	}, []);

	useEffect(() => {
		window.addEventListener("resize", () => setSize(window.innerWidth));
	}, []);

	useEffect(() => {
		SavingsPlans.getSavingsPlan(savingsPlanId).then(
			(data: SavingsPlanType) => {
				setSavingsPlan(data);
			},
			error => {
				setError(error);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		UserIdentitiesService.getIdentitiesByUserId(user.id).then(
			(userIdentities: UserIdentityType[]) => {
				setUserIdentities(userIdentities);
				setIsLoaded(true);
			},
			error => {
				setError(error);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fullName = (identity: UserIdentityType) => {
		var fullName = "";

		if (identity.firstName !== undefined && identity.firstName !== "") {
			fullName = identity.firstName;
		}
		if (identity.lastName !== undefined && identity.lastName !== "") {
			fullName += " " + identity.lastName;
		}
		if (identity.secondLastName !== null && identity.secondLastName !== "") {
			fullName += " " + identity.secondLastName;
		}
		return fullName;
	};

	const redirectToMap = () => {
		history.push("/configurationMap", {
			savingPlanId: savingsPlan?.id,
			savingPlanName: savingsPlan?.name
		});
	};

	const continueToNextStep = () => {
		var nextStep = "2.3";
		if (savingsPlan !== undefined && [0, 1].includes(savingsPlan.type)) {
			nextStep = "2.2";
		}
		SavingsPlanService.patchSavingsPlan(savingsPlanId, { configurationMapStep: nextStep }).then(
			() => {
				redirectToMap();
			},
			(error: any) => {
				setError(error);
			}
		);
	};

	return (
		<DocumentTitle title={`IronIA - Intervinientes ${savingsPlan?.name !== undefined ? "- " + savingsPlan.name : ""}`}>
			<React.Fragment>
				<S.HelperStyle />
				<S.Cont theme={theme}>
					<S.MainCont>
						<S.Steps>
							<h3>
								Paso 1 <span>de 2</span>
							</h3>
						</S.Steps>
						<S.Title>
							<h1>Interviniente</h1>
							<h2>Aquí puedes gestionar los distintos tipos de intervinientes de tus planes de cartera.</h2>
						</S.Title>
						<S.BarCont>
							<S.BgBar />
							<S.ProsBar />
						</S.BarCont>
						{userIdentities !== undefined && (
							<S.TabletCont theme={theme}>
								{size > 899 ? (
									<S.Table>
										<tbody>
											<tr>
												<th>Nombre y apellidos</th>
												<th>Correo electrónico</th>
												<th className="tight">Menor de edad</th>
												<th className="tight">Conocer a nuestro cliente</th>
												<th className="tight"></th>
											</tr>
											{userIdentities.length > 0 &&
												userIdentities.map((userIdentity: UserIdentityType, index: number) => {
													var currentDate = Moment();
													var birthdayDate = Moment(userIdentity.birthday);
													var isMinor = currentDate.diff(birthdayDate, "years") < 18;
													return (
														<tr key={"index" + index}>
															<td>{userIdentity.firstName ? fullName(userIdentity) : user.fullName}</td>
															<td>{userIdentity.email ? userIdentity.email : user.email}</td>
															<td className="text-center">{isMinor ? "Sí" : "No"}</td>
															<td className="text-center">
																{userIdentity.status !== undefined && userIdentity.status === "NEW" && <span>No</span>}
																{userIdentity.status !== undefined &&
																	["PENDING", "ACTIVE", "OUTDATED", "CANCELLED"].includes(userIdentity.status) && (
																		<span>Sí</span>
																	)}
															</td>
															<td>
																<DefaultButton
																	className="button mini-primary-button"
																	onClick={() => history.push("/accountHolderData/" + savingsPlanId + "/" + userIdentity.id)}
																>
																	Editar
																</DefaultButton>
															</td>
														</tr>
													);
												})}
										</tbody>
									</S.Table>
								) : (
									<React.Fragment>
										{userIdentities.length > 0 && (
											<S.Table>
												<tbody></tbody>
												{userIdentities.forEach((userIdentity: UserIdentityType, index: number) => {
													var currentDate = Moment();
													var birthdayDate = Moment(userIdentity.birthday);
													var isMinor = currentDate.diff(birthdayDate, "years") < 18;
													return (
														<tbody key={"index" + index}>
															<tr>
																<th>Nombre y apellidos</th>
																<td>{userIdentity.firstName ? fullName(userIdentity) : user.fullName}</td>
															</tr>
															<tr>
																<th>Correo electrónico</th>
																<td>{userIdentity.email ? userIdentity.email : user.email}</td>
															</tr>
															<tr>
																<th className="tight">Menor de edad</th>
																<td className="text-center">{isMinor ? "Sí" : "No"}</td>
															</tr>
															<tr>
																<th className="tight">Conocer a nuestro cliente</th>
																<td className="text-center">
																	{userIdentity.status !== undefined && userIdentity.status === "NEW" && <span>No</span>}
																	{userIdentity.status !== undefined &&
																		["PENDING", "ACTIVE", "OUTDATED", "CANCELLED"].includes(userIdentity.status) && (
																			<span>Sí</span>
																		)}
																</td>
															</tr>
															<tr>
																<th className="tight"></th>
																<td>
																	<DefaultButton
																		className="button mini-primary-button"
																		onClick={() =>
																			history.push("/accountHolderData/" + savingsPlanId + "/" + userIdentity.id)
																		}
																	>
																		Editar
																	</DefaultButton>
																</td>
															</tr>
														</tbody>
													);
												})}
											</S.Table>
										)}
									</React.Fragment>
								)}
								{!isLoaded && (
									<div className="ms-Grid-row">
										<Spinner size={SpinnerSize.large} />
									</div>
								)}
								<div className="ms-Grid-row">
									{userIdentities.length === 1 && userIdentities[0].status === "NEW" && (
										<div className="ms-Grid-col mt20">
											<DefaultButton
												className="button tiny-secondary-button"
												onClick={() => history.push("/accountHolderData/" + savingsPlanId + "/" + userIdentities[0].id)}
											>
												Completar datos
											</DefaultButton>
										</div>
									)}
									{(userIdentities.length > 1 || (userIdentities.length === 1 && userIdentities[0].status !== "NEW")) && (
										<div className="ms-Grid-col mt20">
											<DefaultButton
												className="button tiny-secondary-button"
												onClick={() => history.push("/accountHolderData/" + savingsPlanId)}
											>
												Añadir intervinientes para esta cuenta
											</DefaultButton>
										</div>
									)}
									{/* {isLoaded && (
										<div className="ms-Grid-col mt20">
											<DefaultButton className="button tiny-secondary-button" onClick={() => setShowExistingAccountModal(true)}>
												Añadir intervinientes existentes en IronIA
											</DefaultButton>
										</div>
									)} */}
								</div>
							</S.TabletCont>
						)}
						{(userIdentities.length > 1 || (userIdentities.length === 1 && userIdentities[0].status !== "NEW")) &&
							savingsPlan?.configurationMapStep === "2.1" && (
								<S.BtnCont>
									<DefaultButton onClick={() => continueToNextStep()} className="button tiny-secondary-button" disabled={!isLoaded}>
										Pasar al siguiente paso
									</DefaultButton>
								</S.BtnCont>
							)}
						{savingsPlan?.configurationMapStep !== "2.1" && (
							<S.BtnCont>
								<DefaultButton onClick={() => redirectToMap()} className="button tiny-secondary-button" disabled={!isLoaded}>
									Volver al mapa
								</DefaultButton>
							</S.BtnCont>
						)}
					</S.MainCont>
				</S.Cont>

				{/* Existing Account Holder Modal*/}
				{showExistingAccountModal && (<ModalAddExistingAccountHolder
					showModal={showExistingAccountModal}
					handleSubmit={(dni, signatureSuccess) => {
						setShowExistingAccountModal(false);
						if (signatureSuccess) {

						}
					}}
				></ModalAddExistingAccountHolder>)}
			</React.Fragment>
		</DocumentTitle>
	);
};

export default ConvenienceTestAccountHolder;
