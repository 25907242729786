import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IChatGPT
{
    isin: string,
    fundName: string,
    indexName: string,
    currency: string,
    annualReturn: number,
    indexAnnualReturn: number,
    volatility: number,
    indexVolatility: number,
    fee: number,
    ironiaPoints: number,
    managerName: string,
    forceNew: boolean,
    userId: number
}
export type ChatGPT = IChatGPT;
interface ILatestVideoInformation
{
    videoExists: boolean,
    dateLastVideo: string
}
export type LatestVideoInformation = ILatestVideoInformation;
interface FundExplanation
{
    explanation: string,
    urlAudio: string,
    urlVideo: string,
    isAudioAvailable: boolean,
    isVideoAvailable: boolean,
    isFundPromoted: boolean,
    price: number,
    textDate: string,
    videoDate: string
}
interface IChatGPTResponse
{
    isin: string,
    managerName: string,
    isGeneratingVideo?: boolean,
    fund: FundExplanation,
    lastFund: FundExplanation,
    userId: number,
    elementsIA?: number
}
export type ChatGPTResponse = IChatGPTResponse;
interface IVideoView
{
    userId: number,
    savingsPlanId: number,
    isin: string,
    isLastVideo?: boolean,
    videoDate?: string
}
export type VideoView = IVideoView;
interface IVideoViewResponse
{
    errorMessage: string,
    elementsIAUsed: number,
    elementsIARemaining: number,
    urlVideo?: string,
    isGeneratingVideo?: boolean
}
export type VideoResponse = IVideoViewResponse;
interface IVideoData
{
    date: string,
    isins: string[]
}
export type VideoData = IVideoData;
interface IVideoURLData
{
    url: string,
    videoDate: string,
    manager: string
}
export type VideoURLData = IVideoURLData;
interface IPromotionedResult
{
    totalCount: number,
    list: PromotionedVideos[]
}
export type PromotionedResult = IPromotionedResult;
interface IPromotionedVideos
{
    id: number,
    videoDate: string,
    url: string,
    isin: string,
    cleanShare: boolean,
    score: number,
    managerName: string,
    managerId: string,
    category: string,
    subcategory: string,
    familyName: string,
    name: string
}
export type PromotionedVideos = IPromotionedVideos;
class IroniaIntegrationsService
{
    public getChatGPTInfo = async (chatGPTModel: ChatGPT): Promise<ChatGPTResponse> =>
    {
        let url: string = `${proxy}/ChatGPT`;
        const req = await axios.post(url, chatGPTModel, {headers: {"content-type": "application/json"}});
        return req.data;
    }
    public getLatestVideoInformation = async (isin: string): Promise<LatestVideoInformation> =>
    {
        let url: string = `${proxy}/GetLatestVideoInformation/${isin}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getAllVideos = async(): Promise<VideoData[]> =>
    {
        let url: string = `${proxy}/GetAllVideos`;
        const req = await axios.get(url);
        return req.data;
    }
    public getAllPromotionedVideos = async(page: number, searchText: string): Promise<PromotionedResult> =>
    {
        let url: string = `${proxy}/GetAllPromotionedVideos/${page}/${searchText}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getVideoURL = async(isin: string, date: string): Promise<VideoURLData> =>
    {
        let url: string = `${proxy}/GetVideoDIDUrl/${isin}/${date}`;
        const req = await axios.get(url);
        return req.data;
    }
    public addVideoView = async(videoView: VideoView): Promise<VideoResponse> =>
    {
        let url: string = `${proxy}/AddVideoView`;
        const req = await axios.post(url, videoView, {headers: {"content-type": "application/json"}});
        return req.data;
    }
}

const IronIAIntegrations = new IroniaIntegrationsService();
export default IronIAIntegrations;