import config from '../config';

const baseCdnUrl: string = config.cdn_url

const joinUrlSegments = (segments: string[]): string => {
  if (segments?.length === 0) {
    return ''
  }

  const lastSegmentIndex = segments.length - 1

  const urlSegments = segments.map((segment, index) => {
    let urlSegment =
            index > 0 && segment.startsWith('/') ? segment.slice(1) : segment
    urlSegment =
            index < lastSegmentIndex && urlSegment.endsWith('/')
              ? urlSegment.slice(0, -1)
              : urlSegment

    return urlSegment
  })

  return urlSegments.join('/')
}

const getCdnUrl = (path: string): string => {
  if (path === undefined || baseCdnUrl === undefined) {
    return path
  }

  return joinUrlSegments([baseCdnUrl, path])
}

export { getCdnUrl }
