import * as S from '../../ConvenienceTest/ConvenienceTest.style';
import { TextField, DatePicker } from '@fluentui/react';
import Select from 'react-dropdown-select';
import CountryPrefixes from '../../../utils/CountryPrefixes';
import { DayPickerStrings } from '../../ConvenienceTest/Utils/DatesTranlateFluents';
import { DatePickerStyle, InputStyles } from '../../GlobalStyle';
import Moment from 'moment';
import { UserIdentityType } from '../../../services/UserIdentitiesService';

interface UserIdentityContactDataProps {
    userIdentityData: UserIdentityType;
    handleInputChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleDateInputChange: (name: string, value: Date | null | undefined) => void;
    handleSelectChange: (name: string, value: string) => void;
    errors: {
        [key: string]: string;
    };
    disabled: boolean;
}

export const UserIdentityContactData = (props: UserIdentityContactDataProps) => {
    const { userIdentityData, handleInputChange, handleDateInputChange, handleSelectChange, disabled, errors } = props;

    return (
        <>
            <S.FielSet>
                <S.FieldDatePickerCont>
                    <DatePicker
                        styles={DatePickerStyle}
                        label='Fecha de validez'
                        id={'idExpiration'}
                        // @ts-ignore
                        name={'idExpiration'}
                        formatDate={(date) => {
                            return Moment(date).format('DD/MM/YYYY');
                        }}
                        onSelectDate={(date) => { handleDateInputChange('idExpiration', Moment(date).subtract(date?.getTimezoneOffset(), "minutes").toDate()) }}
                        value={userIdentityData?.idExpiration! ? new Date(userIdentityData?.idExpiration!) : undefined}
                        isRequired
                        disabled={disabled}
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        parseDateFromString={(dateStr) => {
                            var parts: string[] = dateStr.split('/');
                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                            return new Date(myDate)
                        }}
                    />
                    {errors.idExpiration !== "" && errors.idExpiration}
                </S.FieldDatePickerCont>
                <S.CustomFieldEmailCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Correo electrónico'
                        id={'email'}
                        name={'email'}
                        type='email'
                        value={userIdentityData?.email!}
                        onChange={handleInputChange}
                        disabled={userIdentityData.main || disabled}
                        required
                    />
                    {errors.email !== "" && errors.email}
                </S.CustomFieldEmailCont>
                <S.CustomFieldPrefixCont>
                    <S.Label>Prefijo (país) - Firma contratos</S.Label>
                    <Select
                        name={'countryPrefix'}
                        labelField='name'
                        options={CountryPrefixes}
                        onChange={(selectedItems) => handleSelectChange("countryPrefix", selectedItems.length === 0 ? "" : selectedItems[0].phone_code)}
                        dropdownHeight='2000'
                        searchable={true}
                        searchBy="text"
                        disabled={disabled}
                        noDataLabel={'No encontrado'}
                        values={CountryPrefixes.filter((cP) => cP.phone_code === (userIdentityData.countryPrefix || "+34"))}
                        placeholder=''
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.name + ' ' + item.phone_code}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'country-prefix-' + item.phone_code + '-' + item.iso3}
                            >
                                {item.name + ' ' + item.phone_code}
                            </span>
                        )}
                        required
                    />
                    {errors.countryPrefix !== "" && errors.countryPrefix}
                </S.CustomFieldPrefixCont>
                <S.FieldPhoneCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Número teléfono móvil para firmar contratos'
                        id={'phone'}
                        name={'phone'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData.phone!}
                        onChange={handleInputChange}
                        required
                        validateOnFocusIn
                    />
                    {errors.phone !== "" && errors.phone}
                </S.FieldPhoneCont>
            </S.FielSet>
            <S.FielSet>
                <S.EmptyHalf />
                <S.CustomFieldPrefixCont>
                    <S.Label>Prefijo (país) - Firma operaciones</S.Label>
                    <Select
                        name={'operationCountryPrefix'}
                        labelField='name'
                        options={CountryPrefixes}
                        onChange={(selectedItems) => handleSelectChange("operationCountryPrefix", selectedItems.length === 0 ? "" : selectedItems[0].phone_code)}
                        dropdownHeight='2000'
                        searchable={true}
                        searchBy='text'
                        disabled={disabled}
                        noDataLabel={'No encontrado'}
                        values={CountryPrefixes.filter((countryPrefix) => countryPrefix.phone_code === (userIdentityData.operationCountryPrefix || "+34"))}
                        placeholder=''
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.name + ' ' + item.phone_code}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'country-prefix-' + item.phone_code + '-' + item.iso3}
                            >
                                {item.name + ' ' + item.phone_code}
                            </span>
                        )}
                        required
                    />
                    {errors.operationCountryPrefix !== "" && errors.operationCountryPrefix}
                </S.CustomFieldPrefixCont>
                <S.FieldPhoneCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Número teléfono móvil para firmar operaciones'
                        id={'operationPhone'}
                        name={'operationPhone'}
                        type='text'
                        value={userIdentityData.operationPhone!}
                        onChange={handleInputChange}
                        disabled={disabled}
                        required
                        validateOnFocusIn
                    />
                    {errors.operationPhone !== "" && errors.operationPhone}
                </S.FieldPhoneCont>
            </S.FielSet>
        </>
    );
};