import * as S from '../ConvenienceTest.style';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { Toggle } from '@fluentui/react';
import { DocumentIntelligenceResult, DocumentUpload } from '../../IntervinentForm/DocumentUpload/DocumentUpload';
import { UserIdentityType } from '../../../services/UserIdentitiesService';
import { UserIdentityPersonalData } from '../../IntervinentForm/UserIdentityPersonalData/UserIdentityPersonalData';
import { FiscalAddress } from '../../IntervinentForm/FiscalAddress/FiscalAddress';
import { PostalAddress } from '../../IntervinentForm/PostalAddress/PostalAddress';
import { UserIdentityContactData } from '../../IntervinentForm/UserIdentityContactData.tsx/UserIdentityContactData';
import { Attachment } from '../../Attachment';
import { parseAddressToModel, setFullNameFunction, getIdType } from '../../../utils/UserIdentity';
import { Countries } from '../../../utils/Countries';

interface Country {
    key: string;
    text: string;
    value: string;
}

const ConvenienceTestAccountHolderDataInputs = ({ // @ts-ignore
    handleChange, handlePostalAddrChange, handleSameAddressChange, handleFiscalAddrChange, updateIntelligenceUserIdentity, data, formVal, setFormVal
}) => {
    const {
        birthday,
        idExpiration,
        email = "",
        countryPrefix = '',
        phone,
        operationCountryPrefix,
        operationPhone,
        proofOfMinorGuardianDescription,
        idFront,
        idBack,
    } = data;

    const [userIdentity, setUserIdentity] = useState<UserIdentityType>(data);
    const [countries] = useState<Country[]>(Countries);
    const [isMinor, setIsMinor] = useState<boolean>(false);
    const [fiscalAddressActive, setFiscalAddressActive] = useState<boolean>(false);
    const [errors, setErrors] = useState(
        {
            firstName: "",
            lastName: "",
            birthday: "",
            email: "",
            countryPrefix: "",
            phone: "",
            operationCountryPrefix: "",
            operationPhone: "",
            proofOfMinorGuardianDescription: "",
            idExpiration: "",
            streetName: "",
            streetNumber: "",
            postal_zipCode: "",
            idType: "",
            birthdayCountry: "",
        });

    const [mainFormLocked, setMainFormLocked] = useState<boolean>(true);
    const getErrorMessage = (field: string, value: any) => {
        switch (field) {
            case 'firstName':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Nombre es obligatorio</S.Error>;
                }
                else {
                    return (value.length === 0)
                        ? <S.Error>El campo Nombre es obligatorio</S.Error>
                        : "";
                }
            case 'lastName':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Primer apellido es obligatorio</S.Error>;
                }
                else {
                    return (value.length === 0)
                        ? <S.Error>El campo Primer apellido es obligatorio</S.Error>
                        : "";
                }
            case 'birthday':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Fecha de nacimiento es obligatorio</S.Error>;
                }
                return null;

            case 'email':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Correo electrónico es obligatorio</S.Error>;
                }

                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value)) {
                    return <S.Error>El campo Correo electrónico debe tener un formato válido</S.Error>
                }
                return '';
            case 'countryPrefix':
                return "";
            case 'operationCountryPrefix':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Prefijo (país) es obligatorio</S.Error>;
                }
                else {
                    return (value.length === 0)
                        ? <S.Error>El campo Prefijo (país) es obligatorio</S.Error>
                        : "";
                }
            case 'secondLastName':
            case 'streetStair':
            case 'streetDoor':
            case 'streetFloor':
            case 'streetOther':
            case 'fiscal_streetName':
            case 'fiscal_streetNumber':
            case 'roadType':
            case 'state':
            case 'city':
                return '';
            case 'operationPhone':
            case 'phone':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Nº de teléfono móvil es obligatorio</S.Error>;
                }
                else {
                    return (value.length < 9)
                        ? <S.Error>El campo Nº de teléfono móvil es obligatorio</S.Error>
                        : "";
                }
            case 'idDocument':
                if (value && value.trim().length < 9) {
                    return <S.Error className="error">El campo Número de documento debe tener al menos 9 caracteres</S.Error>;
                }
                return '';

            case 'idType':
                return value?.trim().length === 0
                    ? <S.Error>El campo Tipo de documento es obligatorio</S.Error>
                    : "";
            case 'proofOfMinorGuardianDescription':
                if (value === null || value === undefined) {
                    return <S.Error className="error">Debe acreditar que es el padre, la madre o el tutor legal del menor</S.Error>;
                }
                else {
                    return (isMinor && value.length === 0)
                        ? <S.Error className="error">Debe acreditar que es el padre, la madre o el tutor legal del menor</S.Error>
                        : "";
                }
            case 'postal_streetNumber':
                return value?.trim().length === 0
                    ? <S.Error>El campo Número de vía es obligatorio</S.Error>
                    : "";
            case 'postal_streetName':
                return value?.trim().length === 0
                    ? <S.Error>El campo Nombre de la vía es obligatorio</S.Error>
                    : "";
            case 'zipCode':
                return value?.trim().length === 0
                    ? <S.Error>El campo código postal es obligatorio</S.Error>
                    : "";
            case 'postal_zipCode':
                return value?.trim().length === 0
                    ? <S.Error>El campo postal_código postal es obligatorio</S.Error>
                    : "";
            case 'idExpiration':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Fecha de validez debe tener un formato válido</S.Error>
                }
                return '';

            default:
                console.error('Error en el switch ', field);
                throw new Error('Error en el switch');
        }
    };

    // INFO: si el id es undefined, bloquea el formulario
    useEffect(() => {
        !!data.id ? setMainFormLocked(false) : setMainFormLocked(true);
    }, [data.id]);

    useEffect(() => {
        if (formVal !== undefined) {
            if (birthday !== undefined) {
                var currentDate = Moment();
                var birthdayDate = Moment(birthday);
                var isMinor = currentDate.diff(birthdayDate, 'years') < 18;
                setIsMinor(isMinor);
            }

            if (userIdentity?.postalAddress?.zipCode! !== '' && userIdentity?.postalAddress?.zipCode! !== undefined) {
                setFormVal("postal_zipCode", true);
            } else {
                setFormVal("postal_zipCode", false);
            }

            Object.keys(data).forEach((key) => {
                if (key in formVal) {
                    if (data[key] === "" || data[key] === null) {
                        if ((key === 'proofOfMinorGuardianDescription' && !isMinor)
                            || (['email', 'phone', 'operationPhone'].includes(key) && isMinor)
                        ) {
                            setFormVal(key, true);
                        }
                        else {
                            setFormVal(key, false);
                        }
                    }
                    else {
                        var error = getErrorMessage(key, data[key]);
                        setErrors((prevState: any) => {
                            return {
                                ...prevState,
                                [key]: error
                            }
                        });
                        setFormVal(key, error === "");
                    }
                }
            });
        }
    }, [data, formVal]);

    const handleCountrySelectChange = (name: string, value: string) => {
        setUserIdentity((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleInputChange = (event: any) => {
        if (event.target !== undefined) {
            var { name, value } = event.target;

            // Actualizar userIdentity
            setUserIdentity(prevState => ({
                ...prevState,
                [name]: value.trim()
            }));

            var error = getErrorMessage(name, value);
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange(event);
        }
    };

    const handlePostalAddressChange = (event: any) => {
        const { name, value } = event.target;
        const realName = name.startsWith('postal_') ? name.slice(7) : name;

        // Actualizar postalAddress en userIdentity
        setUserIdentity((prevState) => ({
            ...prevState,
            postalAddress: {
                ...prevState.postalAddress,
                [realName]: value,
            },
        }));

        // Validar errores
        const error = getErrorMessage(name, value);
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: error,
        }));

        setFormVal(name, error === "");
        handlePostalAddrChange(event);
    };

    const handlePostalAddressInputChange = (event: any) => {
        if (event.target !== undefined) {
            var { name, value } = event.target;
            const realName = name.startsWith('postal_') ? name.slice(7) : name;

            // Actualizar PostalAddress userIdentity
            setUserIdentity((prevState) => ({
                ...prevState,
                postalAddress: {
                    ...prevState.postalAddress,
                    [realName]: value
                }
            }));

            if (name === 'postal_city' || name === 'postal_state') {
                setUserIdentity((prevState) => ({
                    ...prevState,
                    postalAddress: {
                        ...prevState.postalAddress,
                        zipCode: ''
                    }
                }));
                setFormVal('postal_zipCode', false);
            }

            if (name === 'postal_zipCode') {
                const error = getErrorMessage(name, value);
                setErrors((prevState: any) => ({
                    ...prevState,
                    [name]: error,
                }));
                setFormVal(name, error === "");
            }
            handlePostalAddrChange(event);
        }
    };

    const handleFiscalAddressInputChange = (event: any) => {
        if (event.target !== undefined) {
            var { name, value } = event.target;

            // Actualizar FiscalAddress userIdentity
            setUserIdentity((prevState) => ({
                ...prevState,
                fiscalAddress: {
                    ...prevState.fiscalAddress,
                    [name]: value
                }
            }));

            handleFiscalAddrChange(event);
        }
    };

    // INFO: cambia el estado del toggle y envía el evento al padre
    const handleToggleSameAddressChange = (event: React.MouseEvent<HTMLElement>, checked?: boolean | null) => {
        setUserIdentity((prevState) => ({
            ...prevState,
            sameAddress: !checked
        }));
        handleSameAddressChange(event, checked);
        setFiscalAddressActive(!checked ? false : true);

    };

    const handleSelectChange = (name: string, value: string) => {
        handleInputChange({ target: { name, value } } as React.ChangeEvent<HTMLInputElement>);
    };

    const handlePostalAddressSelectChange = (name: string, value: string) => {
        handlePostalAddressInputChange({ target: { name, value } } as React.ChangeEvent<HTMLInputElement>);
    };
    const handleFiscalAddressSelectChange = (name: string, value: string) => {
        handleFiscalAddressInputChange({ target: { name, value } } as React.ChangeEvent<HTMLInputElement>);
    };

    const handleFiscalAddressChange = (event: any) => {
        const { name, value } = event.target;
        const realName = name.startsWith('fiscal_') ? name.slice(7) : name;

        setUserIdentity((prevState) => ({
            ...prevState,
            fiscalAddress: {
                ...prevState.fiscalAddress,
                [realName]: value

            }
        }));
        // Validar errores
        const error = getErrorMessage(name, value);
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: error,
        }));

        setFormVal(name, error === "");
        handleFiscalAddrChange(event);
    };

    const handleMainFormLock = (value: boolean) => {
        setMainFormLocked(value);
    }

    const handleDateInputChange = (name: string, value: any) => {
        if (value !== undefined) {
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    [name]: error
                }
            });

            setFormVal(name, error === "");
            handleChange({ target: { name: name, value: value, files: null } });

            let idDocumentExpiration = Moment(idExpiration);

            // Lógica específica de la fecha
            const currentDate = Moment();
            let birthdayDate = Moment(birthday);

            if (name === 'birthday') {
                birthdayDate = Moment(value);
                setUserIdentity((prevState) => ({
                    ...prevState,
                    birthday: birthdayDate.toDate()
                }));
            }
            if (name === 'idExpiration') {
                idDocumentExpiration = Moment(value);
                setUserIdentity((prevState) => ({
                    ...prevState,
                    idExpiration: idDocumentExpiration.toDate()
                }));
            }

            var isLocalMinor = currentDate.diff(birthdayDate, 'years') < 18;
            if (isLocalMinor) {
                var newError = (proofOfMinorGuardianDescription === null
                    || proofOfMinorGuardianDescription === undefined
                    || proofOfMinorGuardianDescription.length === 0)
                    ? <S.Error className="error">Debe acreditar que es el padre, la madre o el tutor legal del menor</S.Error>
                    : "";

                setErrors((prevState: any) => {
                    return {
                        ...prevState,
                        email: "",
                        countryPrefix: "",
                        phone: "",
                        operationCountryPrefix: "",
                        operationPhone: "",
                        idDocument: "",
                        proofOfMinorGuardianDescription: newError
                    }
                });
                setFormVal("email", true);
                setFormVal("countryPrefix", true);
                setFormVal("phone", true);
                setFormVal("operationCountryPrefix", true);
                setFormVal("operationPhone", true);
                setFormVal("proofOfMinorGuardianDescription", newError === "");
                setFormVal("minorTitular", true);
                setFormVal("birthday", true);
                setFormVal("postal_zipCode", true);
            }
            else {
                setErrors((prevState: any) => {
                    return {
                        ...prevState,
                        email: getErrorMessage("email", email),
                        countryPrefix: getErrorMessage("countryPrefix", countryPrefix),
                        phone: getErrorMessage("phone", phone),
                        operationCountryPrefix: getErrorMessage("operationCountryPrefix", operationCountryPrefix),
                        operationPhone: getErrorMessage("operationPhone", operationPhone),
                        // birthday: getErrorMessage("birthday", birthday),
                    }
                });
                setFormVal("email", getErrorMessage("email", email) === "");
                setFormVal("postal_zipCode", getErrorMessage("postal_zipCode", data.postalAddress?.zipCode!) === "");
                setFormVal("countryPrefix", getErrorMessage("countryPrefix", countryPrefix) === "");
                setFormVal("phone", getErrorMessage("phone", phone) === "");
                setFormVal("operationCountryPrefix", getErrorMessage("operationCountryPrefix", operationCountryPrefix) === "");
                setFormVal("operationPhone", getErrorMessage("operationPhone", operationPhone) === "");
                setFormVal("proofOfMinorGuardianDescription", true);
                setFormVal("minorTitular", true);
                setFormVal("birthday", true);
            }
        }
    };


    const handleUploadDocumentResult = (result: DocumentIntelligenceResult) => {
        const direc = parseAddressToModel(result.address);
        const fullName = setFullNameFunction({
            firstName: result.firstName,
            lastName: result.lastName,
            secondLastName: result.secondLastName
        });
        const updatedData = {
            firstName: fullName.firstName,
            lastName: fullName.lastName,
            secondLastName: fullName.secondLastName,
            birthday: new Date(result.dateOfBirth),
            birthdayCountry: (result.placeOfBirth.length > 2 ? 'ES' : result.placeOfBirth),
            nationalCountry: result.countryRegion || 'ES',
            idExpiration: new Date(result.dateOfExpiration),
            idDocument: result.documentNumber,
            idType: getIdType(result.documentType, result.countryRegion),
            idFront: result.fileNames[0],
            idBack: result.fileNames[1],
            postalAddress: {
                streetName: direc.streetName,
                roadType: direc.roadType === 'C.' ? 'CL' : direc.roadType,
                streetNumber: direc.streetNumber,
                streetFloor: direc.streetFloor,
                streetStair: direc.streetStair,
                streetDoor: direc.streetDoor,
                streetOther: direc.streetOther,
                city: direc.city,
                zipCode: direc.zipCode,
                state: direc.state,
                country: direc.country
            },
            sameAddress: true,
        };
        setUserIdentity(prevState => ({
            ...prevState,
            ...updatedData
        }));
        updateIntelligenceUserIdentity(updatedData);
        setMainFormLocked(false);
    };

    return (
        <>
            <div className="documents-form-container">
                {/* Subida de documentos DocumentIntelligence */}
                <DocumentUpload
                    userId={userIdentity?.userId!}
                    onUpload={handleUploadDocumentResult}
                    lockMainForm={handleMainFormLock}
                    idFront={idFront}
                    idBack={idBack} />

                {/* Datos personales */}
                <UserIdentityPersonalData
                    userIdentityData={userIdentity}
                    handleInputChange={handleInputChange}
                    handleDateInputChange={handleDateInputChange}
                    handleCountrySelectChange={handleCountrySelectChange}
                    handleChange={handleChange}
                    disabled={mainFormLocked}
                    countries={countries}
                    errors={errors}
                />

                {/* proofOfMinorGuardianDescription */}
                <S.FielSet>
                    {isMinor && (
                        <S.ExtendedFieldFileIsMinorCont>
                            <label className="inline">Documento que acredite que usted es el tutor legal del menor *</label>
                            <S.FileInput
                                id={'proofOfMinorGuardianDescription'}
                                name={'proofOfMinorGuardianDescription'}
                                type='file'
                                onChange={handleInputChange}
                            />
                            {proofOfMinorGuardianDescription !== undefined && typeof proofOfMinorGuardianDescription === "string" && (
                                <div>
                                    <Attachment fileName={proofOfMinorGuardianDescription} className="image-preview" />
                                </div>
                            )}
                            {errors.proofOfMinorGuardianDescription !== "" && errors.proofOfMinorGuardianDescription}
                        </S.ExtendedFieldFileIsMinorCont>
                    )}
                </S.FielSet>

                {/* Datos de contacto */}
                {!isMinor &&
                    <UserIdentityContactData
                        userIdentityData={userIdentity}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        handleDateInputChange={handleDateInputChange}
                        disabled={mainFormLocked}
                        errors={errors}
                    />}

                {/* Dirección Postal */}
                <PostalAddress
                    userIdentityData={userIdentity}
                    handleAddressChange={handlePostalAddressChange}
                    handleAddressSelectChange={handlePostalAddressSelectChange}
                    disabled={mainFormLocked}
                    errors={errors}
                    countries={countries}
                />
                <br /><br />

                {/* Dirección Fiscal */}
                <Toggle
                    checked={fiscalAddressActive}
                    disabled={mainFormLocked}
                    onText="Añadir dirección fiscal"
                    offText="Tengo la misma dirección fiscal"
                    onChange={handleToggleSameAddressChange}
                />

                {/* Si no hay dirección fiscal => no me la muestres */}
                {fiscalAddressActive &&
                    < FiscalAddress
                        userIdentityData={userIdentity}
                        handleAddressChange={handleFiscalAddressChange}
                        handleAddressSelectChange={handleFiscalAddressSelectChange}
                        errors={errors}
                        disabled={mainFormLocked}
                        countries={countries}
                    />
                }
            </div >
        </>
    );
};

export default ConvenienceTestAccountHolderDataInputs;