import React, { useCallback, useEffect, useRef, useState } from "react";
import DocumentTitle from "react-document-title";
import { Separator } from "office-ui-fabric-react/lib/Separator";
import ElementCardItem from "./ElementCardItem";
import * as E from "../Elements/ElementStyles";
import Highstock, { GraphicType } from "../../components/Highstock/Highstock";
import Moment from "moment";
import Auth from "../../Auth/Auth";
import ElementService, { ElementPrice, ElementAmountType, ElementTransactionType, SavingsPlanElementsStatus } from "../../services/ElementsService";
import ElementsPromotionsService, { ElementsPromotionType } from "../../services/ElementsPromotionsService";
import { Link } from "react-router-dom";
import { DetailsList, Dropdown, IColumn, IDropdownOption, IDropdownStyles, Spinner, SpinnerSize, TeachingBubble } from "@fluentui/react";
import ElementsIcon from "../../components/Elements/ElementsIcon";
import DynamicElement from "../../components/Elements/DynamicElement";
import * as L from "../../components/Instruments/layout.styles";
import { useId } from "@fluentui/react-hooks";
import SavingsPlanService, { ActiveSubscriptionAndSavingPlans } from "../../services/SavingsPlanService";
import { setActiveSavingsPlan, setSavingsPlanElementsStatus } from "../../store/auth/actions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import UserService from "../../services/UserService";

const dropdownStyles: Partial<IDropdownStyles> = {
    caretDown:
    {
        color: "#cc214F",
        fontSize: "14px !important",
        fontWeight: 700
    },
    dropdownItems:
    {
        color: "#cc214F !important",
        borderColor: "transparent !important",
        fontWeight: 500,
        fontSize: "20px",
        marginTop: "5px"
    },
    title:
    {
        color: "#cc214F !important",
        borderColor: "transparent !important",
        fontWeight: 500,
        fontSize: "20px",
        marginTop: "5px"
    },
    dropdownItemSelected:
    {
        color: "rgb(204, 33, 79) !important",
        backgroundColor: "white"
    },
    dropdownOptionText: { color: "rgb(204, 33, 79) !important" }
};

interface ISerie
{
    name: string;
    showInLegend: boolean;
    tooltip: { valueDecimals: number };
    data: any[];
}

export type Serie = ISerie;

const Elements = () =>
{
    const user = Auth.getUserProfile();
    let activePlan = Auth.getActivePlan();
    const arePricesLoaded = useRef<boolean>(false);
    const [series, setSeries] = useState<any[]>([]);
    const [elementsPromotionsAvailableForUser, setElementsPromotionsAvailableForUser] = useState<ElementsPromotionType[]>();
    const [isLoadingElementsPromotionsAvailableForUser, setIsLoadingElementsPromotionsAvailableForUser] = useState<boolean>(false);
    const [elementsPromotionsAppliedToUser, setElementsPromotionsAppliedToUser] = useState<ElementsPromotionType[]>();
    const [isLoadingElementsPromotionsAppliedToUser, setIsLoadingElementsPromotionsAppliedToUser] = useState<boolean>(false);
    const [elementsAmount, setElementsAmount] = useState<ElementAmountType>();
    const [isLoadingElementsAmount, setIsLoadingElementsAmount] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [recordsTotal, setRecordsTotal] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [tooltipSuscribeElements, setTooltipSuscribeElements] = useState<boolean>(false);
    const localTheme = window.localStorage.getItem("theme") || "light";
    const valorationTooltip = useId('valoration');
    const [tooltipElementsValuation, setTooltipElementsValuation] = useState<boolean>(false);
    const howWorkTooltip = useId('howWork');
    const [tooltipElementsHowWork, setTooltipElementsHowWork] = useState<boolean>(false);
    const [options, setOptions] = useState<IDropdownOption[]>();
    const [isLoadingChange, setLoadingChange] = useState<boolean>(false);
    const dispatch = useDispatch();
    const savingsPlanElementsStatus = useSelector((state: RootStateOrAny) => state.auth.savingsPlanElementsStatus) as SavingsPlanElementsStatus;

    Moment.locale("es");

    const transactionsColumns: IColumn[] =
    [
        {
            key: "date",
            name: "Fecha",
            fieldName: "transactionDate",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: ElementTransactionType) => <span>{Moment(item.transactionDate).format("DD/MM/YYYY")}</span>
        },
        {
            key: "amount_type",
            name: "Elements",
            fieldName: "amount_type",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: ElementTransactionType) =>
            (
                <React.Fragment>
                    <ElementsIcon type={item.amountType} />
                    <span>
                        {item.amountElement} Elements {item.amountType}
                    </span>
                </React.Fragment>
            )
        },
        {
            key: "concept",
            name: "Concepto",
            fieldName: "concept",
            minWidth: 450,
            onRender: (item: ElementTransactionType) =>
            (
                <React.Fragment>
                    <span>{item.concept}</span>
                    <span className="description">{item.description}</span>
                </React.Fragment>
            )
        }
    ];

    useEffect(() =>
    {
        if (arePricesLoaded.current === false)
        {
            ElementService.getAllPricesData().then((response: ElementPrice[]) =>
            {
                if (response.length > 0)
                {
                    arePricesLoaded.current = true;
                    var data: any[] = [];

                    response.map((element: ElementPrice, idx: number) =>
                    {
                        var date = Moment(element.date).valueOf() + 86400000;

                        return data.push([date, element.price]);
                    });

                    setSeries([{ name: "Elements", showInLegend: true, tooltip: { valueDecimals: 5 }, data: data }]);
                }
            });
        }
    }, []);

    useEffect(() => {
        SavingsPlanService.getActiveSubscriptionAndSavingPlan(user.id).then((response: ActiveSubscriptionAndSavingPlans[]) =>
        {
            var aux: IDropdownOption[] = response
                .map(savingsPlan => ({
                    key: savingsPlan.id,
                    text: savingsPlan.planName,
                    selected: savingsPlan.selected,
                    id: savingsPlan.id.toString()
                }));
            setOptions(aux);
        });
    }, []);

    useEffect(() =>
    {
        if (savingsPlanElementsStatus.isSubscribed)
        {
            setIsLoadingElementsPromotionsAvailableForUser(true);
            ElementsPromotionsService.getElementsPromotionsAvailableForSavingsPlan(activePlan.id).then((elementsPromotionsAux: ElementsPromotionType[]) =>
            {
                setElementsPromotionsAvailableForUser(elementsPromotionsAux);
                setIsLoadingElementsPromotionsAvailableForUser(false);

                setIsLoadingElementsPromotionsAppliedToUser(true);
                ElementsPromotionsService.getElementsPromotionsAppliedToSavingsPlan(activePlan.id).then((elementsPromotionsAux: ElementsPromotionType[]) =>
                {
                    setElementsPromotionsAppliedToUser(elementsPromotionsAux);
                    setIsLoadingElementsPromotionsAppliedToUser(false);
                });
            });
        }
        else {
            setElementsPromotionsAvailableForUser([]);
            setElementsPromotionsAppliedToUser([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savingsPlanElementsStatus.isSubscribed]);

    useEffect(() =>
    {
        if (savingsPlanElementsStatus.isSubscribed)
        {
            setIsLoadingElementsAmount(true);
            ElementService.getElementsAmountBySavingsPlanId(activePlan.id).then((elementsAmountAux: ElementAmountType) =>
            {
                setElementsAmount(elementsAmountAux);
                setRecordsTotal(elementsAmountAux?.elementTransactions?.length > 0 ? elementsAmountAux?.elementTransactions?.length + 1 : 0)
                setIsLoadingElementsAmount(false);
            })
            .catch((error) => {
                setElementsAmount(undefined);
                setRecordsTotal(0);
            });
        }
        else {
            setElementsAmount(undefined);
            setRecordsTotal(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan.id, savingsPlanElementsStatus.isSubscribed]);

    useEffect(() => {
        setLoadingChange(false); 
    }, [savingsPlanElementsStatus]);

    const notifyChangedActivePlan =  useCallback(async (activePlan) =>
    {
        await dispatch(setActiveSavingsPlan({ ...activePlan, sync: true }));
    }, []);

    const notifyChangedSavingsPlanElementsStatus =  useCallback(async (activePlanId) =>
    {
        await dispatch(setSavingsPlanElementsStatus(activePlanId));
    }, []);

    const updateSelectedActivePlan = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item && !item.selected)
        {
            setLoadingChange(true);
            setOptions(prevState => {
                if (prevState === undefined) {
                    return [];
                }
                const newState = prevState.map(obj =>
                {
                    return { ...obj, selected: obj.id === item.key.toString() };
                });

                return newState;
            });

            UserService.selectActivePlan(user.id, Number(item.id)).then((response: boolean) =>
            {
                if (response === true)
                {
                    UserService.getUserLastActivePlan(user.id).then(async (activePlanAux: any) =>
                    {
                        Auth.setActivePlan(activePlanAux);
                        await notifyChangedActivePlan(activePlanAux);
                        activePlan = Auth.getActivePlan();

                        await notifyChangedSavingsPlanElementsStatus(activePlanAux.planId);
                    })
                }
                else {
                    setLoadingChange(false);
                }
            });
        }
    };

    return(
        <DocumentTitle title="IronIA - Elements">
            <React.Fragment>
                <div id="suscribeElements">
                    <h2 style={{margin: 0, cursor: "default"}} onMouseEnter={() => setTooltipSuscribeElements(true)}>
                        Elements
                    </h2>
                </div>
                {tooltipSuscribeElements === true &&
                (
                    <TeachingBubble target="#suscribeElements h2"
                        hasCloseButton={true}
                        headline="Elements"
                        onDismiss={() => setTooltipSuscribeElements(!tooltipSuscribeElements)}
                    >
                        <p>
                            Elements, el programa de lealtad de IronIA Fintech,
                            <a href="https://blog.ironia.tech/post/elements2" target="_blank" rel="noopener noreferrer">
                                ¿deseas saber más?
                            </a>
                        </p>
                    </TeachingBubble>
                )}
                <Separator />
                {options !== undefined && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8">
                            {options.length > 0 &&
                            (
                                <Dropdown placeholder={activePlan.name}
                                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl6"
                                    options={options as IDropdownOption[]}
                                    selectedKey={activePlan.id}
                                    onRenderTitle={(items: IDropdownOption[] | undefined) => items !== undefined ? <>{items[0].text}</> : <></>}
                                    onChange={(event, item) => updateSelectedActivePlan(event, item)}
                                    styles={dropdownStyles}
                                    disabled={isLoadingChange}
                                />
                            )}
                            {isLoadingChange && <Spinner size={SpinnerSize.large} className="text-left" style={{marginTop: '15px'}}/>}
                            {!isLoadingChange && !savingsPlanElementsStatus.isSubscribed &&
                            (
                                <Link
                                    className="button tiny-primary-button"
                                    style={{marginTop: '5px'}}
                                    to={'/Elements/Signature'}>
                                    Suscríbete a Elements
                                </Link>
                            )}
                        </div>
                    </div>
                )}
                {!isLoadingChange && savingsPlanElementsStatus.isSubscribed && (
                    <div className="ms-Grid-row">
                        <ElementCardItem
                            fire={elementsAmount?.elementFire || 0}
                            earth={elementsAmount?.elementEarth || 0}
                            air={elementsAmount?.elementAir || 0}
                            water={elementsAmount?.elementWater || 0}
                            ia={elementsAmount?.elementIA || 0}
                            isLoading={isLoadingElementsAmount}
                        />
                    </div>
                )}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8">
                        <div className="shadow elements-values">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6">
                                    <h3>
                                        Valoración Elements
                                        <img src={localTheme === "light" ? "/icons/informationBlack.svg" : "/icons/informationWhite.svg"}
                                            alt="Información"
                                            style={{verticalAlign: "middle", marginLeft: '5px'}}
                                            id={valorationTooltip}
                                            onMouseEnter={() => setTooltipElementsValuation(true)}
                                        />
                                    </h3>
                                    {tooltipElementsValuation === true &&
                                    (
                                        <TeachingBubble target={`#${valorationTooltip}`}
                                            hasCloseButton={true}
                                            onDismiss={() => setTooltipElementsValuation(!tooltipElementsValuation)}
                                        >
                                            <p>
                                                La valoración de los Elements Nature se realiza en base a la actividad de los usuarios en la plataforma.
                                                A mayor actividad, más valor tienen los Elements. Entendemos por actividad cualquier interacción con la
                                                plataforma que suponga un evento de navegación. Los errores de la plataforma se consideran actividad y
                                                penalizan en el cálculo de la valoración. A más errores, más valoración de los Elements Nature.
                                            </p>
                                            <p>
                                                El cálculo se realiza diariamenete. Si deseas saber más sobre el programa Elements consulta
                                                las <Link to='/elements/frameworkAgreement' style={{textDecoration: 'underline'}}>condiciones generales IronIA Elements</Link>.
                                            </p>
                                        </TeachingBubble>
                                    )}
                                </div>
                                <div className="ms-Grid-col ms-sm6 text-right">
                                    <h4>
                                        ¿Cómo funcionan los Elements?
                                        <img src={localTheme === "light" ? "/icons/informationBlack.svg" : "/icons/informationWhite.svg"}
                                            alt="Información"
                                            style={{ verticalAlign: "-5px", marginLeft: '5px' }}
                                            id={howWorkTooltip}
                                            onMouseEnter={() => setTooltipElementsHowWork(true)}
                                        />
                                    </h4>
                                    {tooltipElementsHowWork === true &&
                                    (
                                        <TeachingBubble target={`#${howWorkTooltip}`}
                                            hasCloseButton={true}
                                            onDismiss={() => setTooltipElementsHowWork(!tooltipElementsHowWork)}
                                        >
                                            <p>
                                                Recuerda que los Elements Nature son promociones que se generan al contratar
                                                productos y/o servicios de inversión, pudiendo ser canjeados después por descuentos en
                                                otros productos y/o servicios dentro de la plataforma IronIA. Los Elements IA, sin
                                                embargo, sí que se configuran como saldos pasivos del cliente (al ratio de conversión
                                                establecido en el contrato de Elements). El Cliente puede solicitar su abono en
                                                efectivo en cualquier momento. Tiene a su disposición 
                                                <a href='https://www.blog.ironia.tech/post/elements2'
                                                    target="_blank" rel="noopener noreferrer"
                                                    style={{textDecoration: 'underline'}}>
                                                    información detallada sobre el Programa Elements
                                                </a>.
                                            </p>
                                        </TeachingBubble>
                                    )}
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col12">{arePricesLoaded.current === true && <Highstock customOptions={{}} series={series} graphicType={GraphicType.linearGraphic} />}</div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl4">
                        <div className="shadow elements-promotions">
                            <div className="ms-Grid-row">
                                <h3 style={{cursor: "default"}}>Promociones</h3>
                            </div>
                            {savingsPlanElementsStatus.isSubscribed &&
                            (
                                <React.Fragment>
                                    {(isLoadingElementsPromotionsAvailableForUser || isLoadingChange) && <Spinner size={SpinnerSize.large} />}
                                    {!isLoadingElementsPromotionsAvailableForUser && elementsPromotionsAvailableForUser !== undefined && !isLoadingChange &&
                                    (
                                        <div className="ms-Grid-row">
                                            {elementsPromotionsAvailableForUser.length === 0 &&
                                            (
                                                <React.Fragment>
                                                    No hay ninguna promoción disponible en este momento.
                                                </React.Fragment>
                                            )}
                                            {elementsPromotionsAvailableForUser.length > 0 && elementsPromotionsAvailableForUser.map((elementsPromotion: ElementsPromotionType, elementsPromotionIndex: number) =>
                                            (
                                                <E.InfoSellCard key={"elementsPromotion" + elementsPromotionIndex}>
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <ElementsIcon type={elementsPromotion.amountType} />
                                                        <h4>{elementsPromotion.name}</h4>
                                                    </div>
                                                    {elementsPromotion.endDate !== null &&
                                                    (
                                                        <div className="ms-Grid-col ms-sm12">
                                                            Finaliza el {new Date(elementsPromotion.endDate).toLocaleString()}
                                                        </div>
                                                    )}
                                                    <div className="ms-Grid-col ms-sm12">{elementsPromotion.description}</div>
                                                    {elementsPromotion.childrens.length > 0 &&
                                                    (
                                                        <div className="ms-Grid-col ms-sm12 childrens">
                                                            {elementsPromotion.childrens.map((children: ElementsPromotionType, childrenIndex: number) =>
                                                            (
                                                                <div className="ms-Grid-col ms-sm12 children" key={"availableElementsPromotion" + elementsPromotionIndex + "-" + childrenIndex}>
                                                                    <div className="ms-Grid-col ms-sm12">
                                                                        <ElementsIcon type={children.amountType} />
                                                                        <h4 style={{cursor: "default"}}>
                                                                            {children.name}
                                                                        </h4>
                                                                    </div>
                                                                    {children.endDate !== null &&
                                                                    (
                                                                        <div className="ms-Grid-col ms-sm12" style={{cursor: "default"}}>
                                                                            Finaliza el {new Date(children.endDate).toLocaleString()}
                                                                        </div>
                                                                    )}
                                                                    <div className="ms-Grid-col ms-sm12" style={{cursor: "default"}}>
                                                                        {children.description}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <div className="ms-Grid-col ms-sm12 text-right">
                                                        {elementsPromotion.elementsButton !== undefined &&
                                                        (
                                                            <DynamicElement element={elementsPromotion.elementsButton} sending={sending}
                                                                setSending={setSending} text="Suscribir"
                                                            />
                                                        )}
                                                    </div>
                                                </E.InfoSellCard>
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mas-xl8 ms-xxl8">
                        <div className="shadow elements-transactions">
                            <h3 style={{cursor: "default"}}>Transacciones de Elements</h3>
                            {(isLoadingElementsAmount || isLoadingChange) && <Spinner size={SpinnerSize.large} />}
                            {!isLoadingElementsAmount && savingsPlanElementsStatus.isSubscribed && elementsAmount?.elementTransactions !== undefined && !isLoadingChange &&
                            (
                                <React.Fragment>
                                    {elementsAmount?.elementTransactions?.length === 0 &&
                                    (
                                        <React.Fragment>
                                            Todavía no has realizado ninguna transacción con los Elements.
                                        </React.Fragment>
                                    )}
                                    {elementsAmount?.elementTransactions?.length > 0 &&
                                    (
                                        <React.Fragment>
                                            <DetailsList items={elementsAmount?.elementTransactions?.slice(page * itemsPerPage, (page + 1) * itemsPerPage)} columns={transactionsColumns} selectionMode={0} />
                                            <L.PaginationContainer>
                                                <L.ProductsPerPage>
                                                    <span style={{cursor: "default"}}>
                                                        Resultados por página
                                                    </span>
                                                    <select defaultValue={itemsPerPage}
                                                        onChange={event =>
                                                        {
                                                            setItemsPerPage(Number(event.target.value));
                                                            setPage(0);
                                                        }}
                                                    >
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={30}>30</option>
                                                        <option value={40}>40</option>
                                                        <option value={50}>50</option>
                                                    </select>
                                                </L.ProductsPerPage>
                                                <L.PageCount>
                                                    <span style={{cursor: "default"}}>{`${page + 1} de ${Math.ceil(recordsTotal / itemsPerPage)}`}</span>
                                                </L.PageCount>
                                                <L.PageButtons>
                                                    <button className={page === 0 ? "disabled" : ""}
                                                        onClick={() =>
                                                        {
                                                            if(page > 0)
                                                            {
                                                                setPage(page-1);
                                                            }
                                                        }}  
                                                    >
                                                        <i className={"ironia-icon keyboard-arrow-left"} />
                                                    </button>
                                                    <button className={Math.ceil(recordsTotal / itemsPerPage!) === page+1 ? "disabled" : ""}
                                                        onClick={() =>
                                                        {
                                                            if(page < Math.ceil(recordsTotal / itemsPerPage!)-1)
                                                            {
                                                                setPage(page+1);
                                                            }
                                                        }}
                                                    >
                                                        <i className={"ironia-icon keyboard-arrow-right"} />
                                                    </button>
                                                </L.PageButtons>
                                            </L.PaginationContainer>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl4">
                        <div className="shadow elements-promotions">
                            <div className="ms-Grid-row">
                                <h3 style={{cursor: "default"}}>Promociones Activas</h3>
                            </div>
                            {savingsPlanElementsStatus.isSubscribed &&
                            (
                                <React.Fragment>
                                    {(isLoadingElementsPromotionsAppliedToUser || isLoadingChange) && <Spinner size={SpinnerSize.large} />}
                                    {!isLoadingElementsPromotionsAppliedToUser && elementsPromotionsAppliedToUser !== undefined && !isLoadingChange &&
                                    (
                                        <div className="ms-Grid-row">
                                            {elementsPromotionsAppliedToUser.length === 0 &&
                                            (
                                                <React.Fragment>
                                                    No te has suscrito a ninguna promoción.
                                                </React.Fragment>
                                            )}
                                            {elementsPromotionsAppliedToUser.length > 0 && elementsPromotionsAppliedToUser.map((elementsPromotion: ElementsPromotionType, elementsPromotionIndex: number) =>
                                            (
                                                <E.InfoSellCard key={"elementsPromotion"+elementsPromotionIndex}>
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <ElementsIcon type={elementsPromotion.amountType} />
                                                        <h4 style={{cursor: "default"}}>{elementsPromotion.name}</h4>
                                                    </div>
                                                    {elementsPromotion.endDate !== null &&
                                                    (
                                                        <div className="ms-Grid-col ms-sm12" style={{cursor: "default"}}>
                                                            Finaliza el {new Date(elementsPromotion.endDate).toLocaleString()}
                                                        </div>
                                                    )}
                                                    <div className="ms-Grid-col ms-sm12" style={{cursor: "default"}}>
                                                        {elementsPromotion.description}
                                                    </div>
                                                    {elementsPromotion.childrens.length > 0 &&
                                                    (
                                                        <div className="ms-Grid-col ms-sm12 childrens">
                                                            {elementsPromotion.childrens.map((children: ElementsPromotionType, childrenIndex: number) =>
                                                            (
                                                                <div className="ms-Grid-col ms-sm12 children" key={"appliedElementsPromotion" + elementsPromotionIndex + "-" + childrenIndex}>
                                                                    <div className="ms-Grid-col ms-sm12">
                                                                        <ElementsIcon type={children.amountType} />
                                                                        <h4 style={{cursor: "default"}}>{children.name}</h4>
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm12" style={{cursor: "default"}}>
                                                                        {children.description}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </E.InfoSellCard>
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default Elements;