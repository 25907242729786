import * as S from '../ConvenienceTest.style';
import Moment from "moment";
import { SavingsPlanUserIdentityType } from '../../../services/SavingsPlanUserIdentityService';
import { UserIdentityType } from '../../../services/UserIdentitiesService';
import SavingsPlanService, { SavingsPlanType, SavingsPlanTypes } from '../../../services/SavingsPlanService';
import { useHistory, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { DefaultButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import React from 'react';
import DocumentTitle from 'react-document-title';
import Auth from "../../../Auth/Auth";
import { ai } from "../../../services/TelemetryService";
import { ThemeContext } from '@fluentui/react-theme-provider';

const agreementText = "Confirmo que autorizo la inversión en las categorías marcadas en la TABLA 2";

const ConvenienceTestContract = () =>
{
    const { savingsPlanId } = useParams<any>();
    const history = useHistory();
    let user = Auth.getUserProfile();
    const theme = useContext(ThemeContext);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [signatures, setSignatures] = useState<any[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [contractHTML, setContractHTML] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [isContractLoaded, setIsContractLoaded] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [annexAgreement, setAnnexAgreement] = useState('');
    const [agreementError, setAgreementError] = useState({ status: 0, message: '' });
    const [error, setError] = useState({ status: 0, message: '' });
    const appInsights = ai.reactPlugin;

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: SavingsPlanType) => {
            setSavingsPlan(savingsPlan)
            setIsLoaded(true);
        },
        (error) => {
            setError(error);
            setIsLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (savingsPlan?.savingsPlansUserIdentities !== undefined) {
            setUserIdentities(savingsPlan.savingsPlansUserIdentities
                .filter((sPUI: SavingsPlanUserIdentityType) => {
                    if (sPUI.userIdentity === undefined) {
                        return false
                    }

                    var currentDate = Moment();
                    var birthdayDate = Moment(sPUI.userIdentity.birthday);
                    return currentDate.diff(birthdayDate, 'years') > 17;
                })
                .map(sPUI => sPUI.userIdentity)
            );
        }
    }, [savingsPlan]);

    useEffect(() => {
        SavingsPlanService.getHTMLContract(savingsPlanId).then((contractHTML: any) => {
            setContractHTML(contractHTML);
            setIsContractLoaded(true);
        });
    }, []);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3)
            + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleSubmit = () =>
    {
        setIsSending(true);
        if (savingsPlan?.user?.afilliateId !== undefined && savingsPlan?.user?.afilliateId !== null) {
            history.push('/tripartiteContract/' + savingsPlanId);
            return;
        }

        if(userIdentities.length > 0)
        {
            SavingsPlanService.addSignaturesToSavingsPlanUserIdentities(savingsPlanId).then((savingsPlanUserIdentitiesAux: any) =>
            {
                var signatures = savingsPlanUserIdentitiesAux
                    .filter((sPUI: SavingsPlanUserIdentityType) => sPUI.signature !== null)
                    .map((sPUI: SavingsPlanUserIdentityType) => sPUI.signature);

                setSignatures(signatures);
                setIsSending(false);
            });
        }
    };

    useEffect(() =>
    {
        if(userIdentities.length > 0 && userIdentities.length === signatures.length)
        {
            history.push('/confirm/'+savingsPlanId, {signatures: signatures});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdentities, signatures]);

    const [bottom, setBottom] = useState(false);

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
        setBottom(bottom)
    };

    const handleInputChange = (event: any) => {
        if (event.target !== undefined) {
            var { value } = event.target;
            setAgreementError({
                status: value === agreementText ? 0 : 1,
                message: (value !== agreementText) ? "Debes transcribir el texto indicado" : ""
            });
            setAnnexAgreement(value);
        }
    };

    useEffect(() => {
        if (annexAgreement === agreementText) {
            appInsights.trackEvent({
                name: "Aceptación inversiones Plan Gestionado",
                properties: {
                    title: "Texto de aceptación de las inversiones indicadas en la tabla 2 del anexo del contrato de gestión",
                    username: user.fullName,
                    userId: user.id,
                    clickTime: new Date().toLocaleDateString() + new Date().toLocaleTimeString()
                }
            });
        }
    }, [annexAgreement]);

    return(
        <DocumentTitle title={`IronIA - Contrato`}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.MainCont>
                        <S.Title>
                            <h1>Firma del Contrato</h1>
                            <h2>
                                A continuación te incluimos los detalles del contrato con nosotros. Léelo y
                                cuando estés listo, dale al botón de "solicitar firma". Te enviaremos un
                                código SMS por cada interviniente
                                {userIdentities.length > 1
                                    ? ` a los números ${phoneNumbers} `
                                    : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " "
                                }
                                para realizar la firma del contrato.
                            </h2>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar/>
                            <S.ProsBar/>
                        </S.BarCont>

                        {isContractLoaded && (
                            <>
                                <S.ScrollableTabletCont
                                    onScroll={handleScroll}
                                    dangerouslySetInnerHTML={{ __html: contractHTML}}
                                />

                                {savingsPlan?.type === SavingsPlanTypes.Saver && (
                                    <Stack className="section-wrapper">
                                        <Stack.Item className="base-column">
                                        </Stack.Item>
                                        <Stack.Item className="base-column">
                                            <label>
                                                Escribe el siguiente texto para autorizar la inversión: "Confirmo que autorizo la inversión en las categorías marcadas
                                                en la <a href='#table-2'>TABLA 2</a>" y visualiza la totalidad del contrato navegando hasta el final del mismo.
                                                <TextField
                                                    placeholder={agreementText}
                                                    type='text'
                                                    value={annexAgreement}
                                                    onChange={handleInputChange}
                                                />
                                            </label>
                                            {agreementError.status === 1 && (
                                                <S.InlineError>{agreementError.message}</S.InlineError>
                                            )}
                                        </Stack.Item>
                                    </Stack>
                                )}
                            </>
                        )}

                        {(!isLoaded || !isContractLoaded)&& (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        <S.BtnCont>
                            <DefaultButton
                                onClick={() => history.goBack()}
                                className="button tiny-secondary-button"
                            >
                                Volver
                            </DefaultButton>
                            <DefaultButton
                                className="button tiny-primary-button"
                                onClick={handleSubmit}
                                disabled={
                                    !isLoaded
                                    || !bottom
                                    || (savingsPlan?.type === SavingsPlanTypes.Saver
                                        && (annexAgreement === '' || annexAgreement !== agreementText))}
                            >
                                {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Solicitar Firma
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestContract;