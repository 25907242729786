import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

const DocumentIntelligenceService = {
    async sendDocumentToVerify(userId: number, data: any): Promise<any> {
        let url: string = `${proxy}/userIdentities/${userId}/upload-documents`;

        const formData = new FormData();
        if (data.documentoAnverso) {
            formData.append('documentoAnverso', data.documentoAnverso);
        }
        if (data.documentoReverso) {
            formData.append('documentoReverso', data.documentoReverso);
        }

        try {
            const req = await axios.post(url, formData);
            return req.data;
        } catch (error: any) {
            if (error.response) {
                const backendMessage = error.response.data;
                return backendMessage;
            } else {
                return 'Error al enviar los documentos';
            }
        }
    },
};

export default DocumentIntelligenceService;